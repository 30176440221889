<template>
  <div class="achievements">
    <div
      :class="['achievements-icon', index >= 6 ? 'clearmb' : 'm-b']"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="image">
        <el-image
          :src="
            require('../../../assets/images/projectIntroduction/icon_35.png')
          "
        ></el-image>
      </div>
      <div class="desc-box">
        <div class="desc-title">
          <span>{{ item.number }}</span>
          {{ item.company }}
        </div>
        <div class="desc-icon"></div>
        <p class="desc-content">{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    achList: Object,
  },
  data() {
    return {
      list: [],
    }
  },
  mounted() {},
  watch: {
    achList() {
      this.list = [
        {
          number: this.achList.duration,
          company: '年',
          content: '已持续时间',
        },
        {
          number: this.achList.expert,
          company: '人',
          content: '阅读教育专家、学者',
        },
        {
          number: this.achList.province,
          company: '个',
          content: '遍及我省地市',
        },
        {
          number: this.achList.county,
          company: '个',
          content: '遍及县（市、区）',
        },
        {
          number: this.achList.school,
          company: '所',
          content: '受益学校 ',
        },
        {
          number: this.achList.readingRoom,
          company: '间',
          content: '阅览室 ',
        },
        {
          number: this.achList.book,
          company: '万册',
          content: '图书 ',
        },
        {
          number: this.achList.student,
          company: '万人',
          content: '惠及学生',
        },
        {
          number: this.achList.parents,
          company: '万人',
          content: '家长 ',
        },
        {
          number: this.achList.seminar,
          company: '场',
          content: '校园阅读研讨会',
        },
        {
          number: this.achList.activity,
          company: '场',
          content: '阅读推广活动',
        },
        {
          number: this.achList.train,
          company: '场',
          content: '家长阅读教育培训会 ',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scope>
.achievements {
  padding: 60px 0;
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  margin: 0 auto;
  .achievements-icon {
    &:hover {
      transform: scale(1.05);
    }
    position: relative;
    width: $mo-main-width-lg/6;
    .desc-box {
      position: absolute;
      top: -20px;
      left: 0;
      .desc-title {
        font-size: 14px;
        color: #222;
        span {
          font-size: 40px;
          font-weight: Bold;
          color: #222222;
        }
      }
      .desc-icon {
        width: 73px;
        height: 1px;
        background: #ca2222;
      }
      .desc-content {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        line-height: 35px;
      }
    }
  }
  .m-b {
    margin-bottom: 90px;
  }
  .clearmb {
    margin: 0;
  }
}
</style>