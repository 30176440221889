<template>
  <div>
    <AppHeader :default-index="5"></AppHeader>
    <Banner :list="bannerList"></Banner>
    <div class="apply">
      <el-row :gutter="60">
        <el-col :span="18">
          <ApplyDetails
            :title="detailsTitle"
            :type="detailsType"
            v-if="isShowDetails != 3"
          ></ApplyDetails>
        </el-col>
        <el-col :span="18" v-if="isShowDetails == 3">
          <div class="title">
            <span>阅读导师志愿者申请</span>
            <div class="step">
              <el-steps :active="activeIndex" align-center>
                <el-step title="信息填写" icon="el-icon-edit"></el-step>
                <el-step title="信息审核" icon="el-icon-upload"></el-step>
                <el-step title="申请成功" icon="el-icon-picture"></el-step>
              </el-steps>
            </div>
          </div>
          <div class="form">
            <el-form
              ref="ruleForm"
              label-width="120px"
              :model="ruleForm"
              :rules="rules"
            >
              <!-- 学校名称 -->
              <el-form-item label="学校名称：" prop="schoolName">
                <el-input
                  v-model="ruleForm.schoolName"
                  placeholder="请输入学校名称"
                ></el-input>
              </el-form-item>
              <!-- 年纪 -->
              <el-row>
                <el-col :span="12">
                  <!-- 年纪 -->
                  <el-form-item label="年级：" prop="grade">
                    <el-input
                      v-model="ruleForm.grade"
                      placeholder="请输入年级"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 班级 -->
                  <el-form-item label="班级：" prop="className">
                    <el-input
                      placeholder="请输入班级"
                      v-model="ruleForm.className"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 姓名 -->
              <el-row>
                <el-col :span="12" class="clearpd">
                  <el-form-item label="姓名：" prop="name">
                    <el-input
                      placeholder="请输入姓名"
                      v-model="ruleForm.name"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 职务 -->
              <el-form-item label="职务：" prop="position">
                <el-radio-group v-model="ruleForm.position">
                  <el-radio label="校长"></el-radio>
                  <el-radio label="教学副校长"></el-radio>
                  <el-radio label="年级组长"></el-radio>
                  <el-radio label="班主任"></el-radio>
                  <el-radio label="语文老师"></el-radio>
                  <el-radio label="其他代课老师"></el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 手机号码 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="手机号码：" prop="tel">
                    <el-input
                      placeholder="请输入手机号码"
                      v-model="ruleForm.tel"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="微信同号：" prop="isWechat">
                    <el-radio-group v-model="ruleForm.isWechat">
                      <el-radio label="是"></el-radio>
                      <el-radio label="否"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    v-if="ruleForm.isWechat == '否'"
                    prop="wechatId"
                  >
                    <el-input
                      placeholder="请输入微信号"
                      v-model="ruleForm.wechatId"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 邮箱 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="邮箱：" prop="email">
                    <el-input
                      placeholder="请输入邮箱"
                      v-model="ruleForm.email"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="btn" @click="submitVolunteer('ruleForm')">
              <span>提交</span>
            </div>
          </div>
        </el-col>
        <!-- 右边 -->
        <el-col :span="6">
          <div class="entrance" @click="toApplication()">
            <el-image
              :src="require('../../assets/images/volunteer/icon_4.png')"
              style="width: 100%"
            ></el-image>
            <div class="entrance-desc">
              <p>家长阅读志愿者申请入口</p>
              <p>(小学生家长)</p>
            </div>
          </div>
          <div class="introduce">
            <div class="sept-title">
              <span class="bor-b"></span>
              <span class="roduce">志愿者申请相关介绍</span>
            </div>
            <div
              class="sept-list"
              v-for="(item, index) in septList"
              :key="index"
            >
              <span
                :class="[index == isShowDetails ? 'active' : '', 'pointer']"
                @click="toUrl(index, '志愿者招募条件', index)"
                >{{ item.title }}</span
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import { bannerDetail, tourVolunteer, dictGrade } from '@/services/api.js'
import ApplyDetails from '@/views/ApplyDetails/ApplyDetails'

export default {
  components: {
    AppHeader,
    Banner,
    ApplyDetails,
    AppFooter,
  },
  data() {
    let checkphone = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入手机号'))
      } else {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            value
          )
        ) {
          callback(new Error('请输入正确手机号'))
        }
        callback()
      }
    }
    let checkemail = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入邮箱'))
      } else {
        if (
          !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            value
          )
        ) {
          callback(new Error('请输入正确邮箱'))
        }
        callback()
      }
    }
    return {
      bannerList: [],
      ruleForm: {
        name: '', // 姓名
        schoolName: '', // 学校姓名
        grade: '', // 年级
        className: '', // 班级
        position: '', // 职务
        tel: '', // 电话
        isWechat: ' ', // 微信是否同号
        wechatId: '', // 微信号
        email: '', // 邮箱
      },
      activeIndex: 1,
      grade: [],
      // 表单验证
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        schoolName: [
          { required: true, message: '请输入学校名称', trigger: 'change' },
        ],
        grade: [{ required: true, message: '请输入年级', trigger: 'change' }],
        className: [{ required: true, message: '请输入班级', trigger: 'blur' }],
        position: [
          { required: true, message: '请选择职务', trigger: 'change' },
        ],
        tel: [{ required: true, validator: checkphone, trigger: 'blur' }],
        isWechat: [
          { required: true, message: '请选择是否微信同号', trigger: 'change' },
        ],
        wechatId: [
          { required: true, message: '请输入微信号', trigger: 'blur' },
        ],
        email: [{ required: true, validator: checkemail, trigger: 'blur' }],
      },
      septList: [
        {
          title: '01 招募条件'
        },
        {
          title: '02 志愿者工作范围'
        },
        {
          title: '03 志愿者待遇'
        },
        {
          title: '04 阅读导师志愿者申请'
        }
      ],
      isValidate: true,
      detailsTitle: '',
      detailsType: 0,
      isShowDetails: 3
    }
  },
  created() {
    dictGrade().then(res_ => {
      this.grade = res_.data.grade
    })
    // 轮播图
    bannerDetail({
      type: '5'
    }).then(res_ => {
      this.bannerList = res_.data
    })
  },
  methods: {
    toUrl(type, title, index) {
      this.detailsTitle = title
      this.detailsType = type
      this.isShowDetails = index
    },
    toApplication() {
      this.$router.push({
        path: '/Application',
      })
    },
    submitVolunteer(formName) {
      this.isValidate
      this.$refs[formName].validate(valid => {
        if (valid) {
          tourVolunteer({
            name: this.ruleForm.name, // 姓名
            schoolName: this.ruleForm.schoolName, // 学校姓名
            grade: this.ruleForm.grade, // 年级
            className: this.ruleForm.className, // 班级
            position: this.ruleForm.position, // 职务
            tel: this.ruleForm.tel, // 电话
            isWechat: this.ruleForm.isWechat, // 微信是否同号
            wechatId: this.ruleForm.wechatId,
            email: this.ruleForm.email, // 邮箱
          }).then(res_ => {
            console.log(res_)
            if (res_.code == 200) {
              this.activeIndex = 2
              this.$alert(res_.msg, '提示', {
                confirmButtonText: '确定',
              })
              this.$refs[formName].resetFields()
            }
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.apply {
  width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
  .title {
    span {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
    }
    .step {
      margin-top: 15px;
      padding: 24px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.4);
      border-radius: 4px;
    }
  }
  .form {
    margin-top: 40px;
    span {
      padding: 0 20px;
    }
    .clearpd {
      padding: 0 !important;
    }
    .m-l {
      margin-left: 45px;
    }
  }
  .entrance {
    margin-top: 40px;
    position: relative;
    .entrance-desc {
      cursor: pointer;
      padding-top: 10px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
      }
    }
  }
  .introduce {
    margin-top: 20px;
    padding: 15px 30px 30px 30px;
    background: #f3f6f8;
    .sept-title {
      position: relative;
      z-index: 4;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      text-align: center;
      line-height: 42px;
      .bor-b {
        width: 100%;
        height: 1px;
        display: block;
        background: #e1e3e6;
        position: absolute;
        top: 50%;
        z-index: 1;
      }
      .roduce {
        position: relative;
        z-index: 3;
        padding: 0 20px;
        background: #f3f6f8;
      }
    }
    .sept-list {
      font-size: 14px;
      font-weight: 400;
      color: #3c3c3c;
      line-height: 50px;
      border-bottom: 1px dashed #e1e3e6;
      .active {
        color: #ca2222;
      }
    }
  }
  .btn {
    cursor: pointer;
    width: 252px;
    height: 50px;
    background: #ca2222;
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    margin-top: 60px;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>