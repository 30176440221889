const ProjectIntroduction = () => import('./ProjectIntroduction.vue')
export default {
  path: '/ProjectIntroduction',
  name: 'ProjectIntroduction',
  component: ProjectIntroduction,
  meta: {
    title: '项目介绍',
    authLogin: false //是否需要登录
  },
  children: []
}

