<template>
  <!--  -->
  <div class="middle">
    <el-row>
      <el-col :span="12" class="b-r ">
        <div class="p-d30">
        <div class="desc-title">
          <span>志愿者招募条件</span>
        </div>
        <div class="desc" v-html="tutorContent.conditions"></div>
        </div>
        <div class="p-d30 b-t">
          <div class="desc-title">
            <span>志愿者工作范围</span>
          </div>
          <div class="desc" v-html="tutorContent.scope"></div>
        </div>
      </el-col>
      <el-col :span="12" class="p-d30">
        <div class="desc-title">
          <span>志愿者待遇</span>
        </div>
        <div class="desc" v-html="tutorContent.treatment"></div>
        <div class="btn" @click="toApply()">
          <span>阅读导师志愿者申请</span>
        </div>
      </el-col>
    </el-row>
  </div>
  <!--  -->
</template>
<script>
export default {
  props: {
    tutorContent: Object,
  },
  methods: {
    toApply() {
      this.$router.push({
        path: '/Apply',
      })
    },
  },
}
</script>

<style lang="scss" scope>
.middle {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.86);
  overflow: hidden;
  .desc-title {
    span {
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      padding-left: 14px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 6px;
        height: 17px;
        background: #ca2222;
      }
    }
  }
  .desc {
    margin: 20px 0;
    p {
      padding-left: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #3c3c3c;
      line-height: 32px;
    }
    .p-l {
      padding-left: 40px;
    }
  }
  .btn {
    cursor: pointer;
    display: inline-block;
    padding: 20px;
    background: #ca2222;
    border-radius: 4px;
    position: absolute;
    right: 50px;
    bottom: 50px;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .p-d30 {
    padding: 30px;
  }
  .b-r {
    border-right: 1px solid #fff;
  }
  .m-t {
    margin-top: -110px;
  }
  .p-d {
    padding: 0 30px;
  }
  .b-t {
    border-top: 1px solid #fff;
  }
  .p-t {
    padding-top: 30px;
  }
}
</style>