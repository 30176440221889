<template>
  <div class="block">
    <div class="block-inner">
      <div class="title">
        <div class="title-inner" :style="{background:background}">
          {{ title }}
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '企业资讯'
    },
    background: {
      type: String,
      default: '#ffffff'
    }
  },
  name: 'Block'
}
</script>

<style scoped lang="scss">
.block {
  width: 100%;
  .block-inner {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    .title {
      width: 350px;
      height: 28px;
      border: 1px solid rgba(202, 34, 0, 0.3);
      position: relative;
      text-align: center;
      .title-inner {
            font-size: 22px;
            font-weight: 600;
            color: #222222;
            background: #fff;
          display: inline-block;
          // margin-left: -50%;
          margin: 0 auto;
          transform: translateY(-50%);
          padding: 0 20px;
          background: #fff;
      }
    }
  }
}
</style>
