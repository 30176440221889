<template>
  <div class="charact-container"> 
    <div class="container-box" v-for="(item,index) in list" :key="index">
      <div class="image">
        <el-image :src="item.image" style="width:60px;height:60px"></el-image>
      </div>
      <div class="right-desc">
        <div class="title">{{item.title}}</div>
        <p class="desc">{{item.content}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          image: require('../../../assets/images/projectIntroduction/icon_45.png'),
          title: '跟踪调研',
          content: '专家进学校，进行跟踪调研，听评阅读课，参加阅读教研活动。'
        },
        {
          image: require('../../../assets/images/projectIntroduction/icon_44.png'),
          title: '课程规划',
          content: '指定学校的阅读课程规划。'
        },
        {
          image: require('../../../assets/images/projectIntroduction/icon_46.png'),
          title: '收集资料',
          content: '进行过程性资料收集，探索有效的阅读评价。'
        },
        {
          image: require('../../../assets/images/projectIntroduction/icon_33.png'),
          title: '进校辅导培训',
          content: '每学期安排多次专家进校辅导培训，或讲座、或示范课、或集体教研、或阅读活动。根据实际提出个性化建议和要求，专家组根据实际尽量满足，同时，每年至少安排一次课题实验校的外出学习机会。'
        },
        {
          image: require('../../../assets/images/projectIntroduction/icon_34.png'),
          title: '名师培养',
          content: '加强学校的名师工程建设。'
        },
        {
          image: require('../../../assets/images/projectIntroduction/icon_36.png'),
          title: '理论提炼',
          content: '帮助老师进行理论提炼，形成课题研究成果。'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scope>
.charact-container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .container-box {
     &:hover {
      transform: scale(1.05);
    }
    width: $mo-main-width-lg / 3 ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    .image {
      flex-shrink: 0;
    }
    .right-desc {
      margin-left: 20px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        margin-bottom: 10px;
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: #4C4C4C;
        
line-height: 30px;
      }
    }
  }
}
</style>