<template>
  <div
    class="journalism-list"
    :style="{ height, border: border ? '1px solid #E1E1E1' : '' }"
  >
    <div class="title" style="height: 60px">
      <slot name="title"></slot>
    </div>
    <div
      class="journalism-list-inner"
      :style="{ border: !border ? '1px solid #E1E1E1' : '' }"
    >
      <div class="list">
        <div class="item pointer" v-for="(item, index) in list" :key="index" @click="toDetaile(item.id)">
          <div class="title">
            <i class="el-icon-caret-right"></i>
              <div class="title-inner e-1">{{ item.title }}</div>
          </div>
          <div class="date">{{ item.noticeTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JournalismList',
  props: {
    height: {
      type: String,
      default: '360px'
    },
    border: Boolean,
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toDetaile(id) {
      this.$router.push({
        path: '/journalismDetails',
        query: {id : id}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.journalism-list {
  display: flex;
  flex-direction: column;
  .title {
    flex-shrink: 0;
  }
  .journalism-list-inner {
    flex-grow: 1;
    .list {
      padding-left: $mo-spacing-row-base;
      .item + .item {
        border-top: 1px #999 dotted;
      }

      .item {
        display: flex;
        justify-content: space-between;
        line-height: 36px;
        color: #3c3c3c;
        padding-right: $mo-spacing-row-base;
        .title {
          display: flex;
          flex-grow: 1;
          align-items: center;
          width: 80%;
          i {
            margin-right: $mo-spacing-row-sm;
            color: #d0d0d0;
          }

          .title-inner {
            flex-grow: 1;
          }
        }
        .date {
          color: #999999;
        }
        &:nth-child(-n + 4) {
          i {
            color: $mo-main-color;
          }
        }
        &:hover {
          color: $mo-main-color;
        }
      }
    }
  }
}
</style>
