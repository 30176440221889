const baseUrl = '/'
const imageUrl = 'https://www.tyruitu.com/'

const config = {
  baseUrl,
  imageUrl,
  apiBaseUrl: baseUrl + 'api/',
  httpDefaultOption: {
    showLoading: true,
    authLogin: true
  },
  CryptoJSKey: '46cc793c53dc451b', //跳转参数链接 参数加密key
  GDKEY: '2682055a67e425b8debc7a5882ac4c9a' //高德地图 key
}

export default config
