<template>
  <div class="main">
    <div class="img">
      <img src="~assets/images/cooperationSchool/icon_55.png" alt="" />
    </div>
    <div class="cooperation">
      <div class="title">
        <span class="school">合作企业</span>
      </div>
      <el-row class="m-t40">
        <el-col
          :span="24"
          class="m-b20"
          v-for="(item, index) in propsData"
          :key="index"
        >
          <div class="space" @click="toOuter(item.url)">
            <div class="el-image">
              <el-image
                :src="`${imageUrl + item.image}`"
                fit="fill"
              ></el-image>
            </div>
            <div class="desc">
              <div class="desc-title">
                <span>{{ item.name }}</span>
                <span :class="item.way == '主办单位' ? 'colorCa' : item.way == '协办单位' ? 'colorFf' : 'colorF'">
                  {{ item.way}}
                  </span>
              </div>
              <div class="desc-contact">
                <span>
                  地址：<i>{{ item.site }}</i>
                </span>
                <span>
                  联系方式：<i>{{ item.tel }}</i>
                </span>
              </div>
              <div class="desc-content">
                <p>{{ item.intro }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsData: Array,
    imageUrl: String
  },
  methods: {
    toOuter(link) {
      console.log(link)
      if(link){
        window.open(`http://${link}`,'_black')
      }else{
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  position: relative;
  .img {
    width: 646px;
    height: 400px;
    position: absolute;
    top: -20px;
    left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cooperation {
    padding-top: 50px;
    padding-bottom: 20px;
    width: 1200px;
    margin: 0 auto;
    .m-t40 {
      margin-top: 40px;
      .m-b20 {
        margin-bottom: 20px;
      }
    }
    .title {
      width: 368px;
      height: 28px;
      background: #ffffff;
      border: 1px solid rgba(202, 34, 0, 0.3);
      margin: 0 auto;
      text-align: center;
      .school {
        font-size: 22px;
        font-weight: 600;
        color: #222222;
        padding: 0 10px;
        background: #fff;
        transform: translatey(-15px);
        display: inline-block;
      }
    }
    .space {
      display: flex;
      align-items: center;
      background: #f5f7f9;
      border-radius: 4px;
      padding: 15px;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      .el-image {
        flex-shrink: 0;
        margin-right: 25px;
        width: 134px;
        height: 134px;
        el-image {
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        &-title {
          font-size: 20px;
          font-weight: 600;
          color: #222222;
          margin-bottom: 20px;
          span {
            &:last-child{
              margin-left: 10px;
              display: inline-block;
              padding: 6px;
              font-size: 14px;
              font-weight: 600;
              border-radius: 4px;
            }
          }
          .colorCa {
            color: #ca2222;
            border: 1px solid #ca2222;
          }
          .colorFf {
            color: #FF7B06;
            border: 1px solid #FF7B06;
          }
          .colorF {
            color: #2061F4;
            border: 1px solid #2061F4;
          }
        }
        &-contact {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-bottom: 15px;
          display: flex;

          span {
            flex: 1;
            i {
              color: #222;
              font-style: normal;
            }
          }
        }
        &-content {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          p {
            line-height: 30px;
            max-height: 60px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>