<template>
  <div class="home">
    <AppHeader :default-index="0" notice :appNotice="homeNew"></AppHeader>
    <div class="home-inner">
      <div class="top-banner container">
        <img src="~images/home/icon_11.png" alt="" />
      </div>
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="13">
            <Banner :list="bannerList" :imageUrl="imageUrl"></Banner>
          </el-col>
          <el-col :span="11">
            <JournalismList v-loading="JournaLogin" :list="homeNotice">
              <template #title>
                <div class="correlation">
                  <div class="left">最新相关资讯</div>
                  <el-button plain type="danger" round size="mini" @click="toLink('/journalist')">
                    查看更多</el-button
                  >
                </div>
              </template>
            </JournalismList>
          </el-col>
        </el-row>
      </div>
      <div class="container" style="margin-top: 20px">
        <el-row :gutter="20">
          <el-col :span="13">
            <JournalismList v-loading="JournalismLogin" element-loading-text="加载中..." :list="homeNew" height="420px" border>
              <template #title>
                <tabs @change="tabClick"></tabs>
              </template>
            </JournalismList>
          </el-col>
          <el-col :span="11">
            <Volunteer></Volunteer>
          </el-col>
        </el-row>
      </div>
      <HomeTitle title="最优秀书评" linkUrl="/Teaching" :index="0">
        <BookReview :imageUrl="imageUrl" :bookReviewList="homeBookReview"></BookReview>
      </HomeTitle>
      <HomeTitle title="最新读书榜单" linkUrl="/Teaching" :index="1">
        <ReadingList :book="homeBookList" :bookType="homeBookTypeList" :imageUrl="imageUrl"></ReadingList>
      </HomeTitle>
      <HomeTitle title="公益课程" linkUrl="/Teaching" :index="2">
        <div class="container">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(v, i) in homeWelfareList" :key="i">
              <CourseItem :item="v" :imageUrl="imageUrl"></CourseItem>
            </el-col>
          </el-row>
        </div>
      </HomeTitle>
      <MemberUnit :schoolFirm="[...homeSchoolList,...homeFirmList]" :imageUrl="imageUrl"></MemberUnit>
    </div>
    <AppFooter :unit="[...homeSchoolList,...homeFirmList]"></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/home/Banner'
import JournalismList from '@/components/content/home/JournalismList'
import tabs from '@/components/content/home/tabs'
import Volunteer from '@/components/content/home/Volunteer'
import HomeTitle from '@/components/content/home/HomeTitle'
import BookReview from '@/components/content/home/BookReview'
import ReadingList from '@/components/content/reading/ReadingList'
import CourseItem from '@/components/content/teaching/CourseItem'
import MemberUnit from '@/components/content/home/MemberUnit'
import config from '@/utils/config'
import { notice, bannerDetail, hotList, videoList, school, Firm, dictGrade, excellentList } from '@/services/api.js'

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter,
    Banner,
    JournalismList,
    tabs,
    Volunteer,
    HomeTitle,
    BookReview,
    ReadingList,
    CourseItem,
    MemberUnit,
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      imageUrl: '',
      // 新闻加载框
      JournalismLogin: true,
      // 咨询加载框
      JournaLogin: true,
      bannerList: [],
      homeNotice: [], // 咨询
      homeNew: [], // 新闻
      homeWelfareList: [], // 公益课程
      homeBookList: [], // 读书榜单
      homeSchoolList: [], // 合作学校
      homeFirmList: [], // 合作企业
      homeBookTypeList: [], // 读书榜单排行榜tab
      homeBookReview: [], // 优秀书评
      tabIndex: 0
    }
  },
  computed: {},

  created() {
    this.imageUrl = config.imageUrl
    // 相关资讯
    this.getNotice('2', '8')
    // 新闻咨询
    this.getNotice('1', '9')
    // 轮播图
    bannerDetail({
      type: '9',
    }).then((res_) => {
      this.bannerList = res_.data
    })
    // 读书榜单
    hotList({
      property: '1',
      pageNo: '1',
      pageSize: '10',
    }).then((res_) => {
      this.homeBookList = res_.data.list
    })
    // 读书榜单排行榜
    dictGrade().then( res_ => {
      this.homeBookTypeList = res_.data.bookType
    })
    // 优秀书评
    excellentList({
        pageNo: '1',
        pageSize: '4'
    }).then(res_ => {
        this.homeBookReview = res_.data.list
    })
    // 公益课程
    videoList({
      property: '2',
      pageNo: '1',
      pageSize: '10',
      isPublic: '1'
    }).then((res_) => {
      this.homeWelfareList = res_.data.list
    })
    // 合作学校
    school().then((res_) => {
      this.homeSchoolList = res_.data
    })
    // 合作企业
    Firm().then((res_) => {
      this.homeFirmList = res_.data
    })
  },
  mounted() {},
  methods: {
    getNotice(type, pageSize, noticeType = '') {
      // 最新公告的加载
      if(type == 1){
        this.JournalismLogin = true
      }
      notice({
        noticeAtt: type,
        noticeType: noticeType,
        pageNo: 1,
        pageSize,
      }).then((res_) => {
        if(res_.code == 200){
          if (type == 2) {
            this.homeNew = res_.data.list
            this.JournaLogin = false
          } else {
            this.homeNotice = res_.data.list
            this.JournalismLogin = false
          }
        }
      })
    },
    toLink(url) {
      this.$router.push({
        path: url
      })
    },
    // tab 切换 最新公告
    tabClick(index) {
      this.tabIndex = index
      this.getNotice(2, '9', index + 1)
    }
  },
  watch: {
    // 监听当最新公告的tab
    tabIndex() {
      this.getNotice('1', '9')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  //outline: 1px solid #4cd964;
}
.home {
  .home-inner {
    .top-banner {
      padding: $mo-spacing-row-base 0;
      height: 160px;
      img {
        object-fit: cover;
      }
    }

    .correlation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 147px;
        height: 42px;
        background: $mo-main-color;
        color: #fff;
        font-size: $mo-font-size-lg;
        line-height: 42px;
        text-align: center;
      }
    }
  }
}
</style>
