const About = () => import('./About.vue')
export default {
  path: '/About',
  name: 'About',
  component: About,
  meta: {
    title: '关于我们',
    authLogin: false //是否需要登录
  },
  children: []
}
