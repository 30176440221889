const Apply = () => import('./Apply.vue')
export default {
  path: '/Apply',
  name: 'Apply',
  component: Apply,
  meta: {
    title: '志愿者申请',
    authLogin: false //是否需要登录
  },
  children: []
}

