<template>
  <div class="reading-list">
    <div class="container">
      <el-row :gutter="50">
        <el-col :span="18">
          <div class="bookList">
            <router-link
              class="item pointer"
              v-for="(v, i) in book"
              :key="i"
              :to="{ path: '/bookStoreDetails', query: { id: v.id } }"
              target="_blank"
            >
              <div class="img">
                <el-image
                  :src="`${imageUrl + v.mainImage}`"
                  fit="cover"
                ></el-image>
                <div class="tag" v-if="i < 4">{{ i + 1 }}</div>
                <div class="tag-bg" v-if="i < 3"></div>
              </div>
              <!-- <el-tooltip  effect="light" :content="v.name" placement="top"> -->
              <div class="title">
                {{ v.name }}
              </div>
              <!-- </el-tooltip>  -->
            </router-link>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="rank-list">
            <div class="tabs">
              <div
                class="tabs-item pointer"
                v-for="(item, index) in bookType"
                :key="index"
                @click="tabsClick(index)"
                :class="{ active: tabsCunnerIndex == index }"
              >
                {{ item.label }}
              </div>
            </div>
            <div
              class="rank-list-inner"
              v-loading="loading"
              element-loading-text="加载中..."
            >
              <router-link
                class="item pointer"
                v-for="(item, index) in spliceList"
                :key="index"
                :to="{ path: '/bookStoreDetails', query: { id: item.id } }"
                target="_blank"
              >
                <span class="m-r">{{ index + 1 }}</span>
                <!-- <el-tooltip  effect="light" :content="item.name" placement="right"> -->
                <span>{{ item.name }}</span>
                <!-- </el-tooltip>  -->
              </router-link>
            </div>
            <div
              class="rank-list-butt pointer"
              @click="bookIndex == 1 ? addBookTop() : toBookReview()"
              v-if="bookIndex != 1"
            >
              {{ bookIndex == 1 ? '' : '查看更多排行榜' }}
              <i class="el-icon-d-arrow-right"></i>
            </div>
            <!-- <div class="rank-list-butt pointer" @click="deleBookTop()" v-else>
              收起排行榜 <i class="el-icon-d-arrow-right"></i>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { goodsBookTop } from '@/services/api.js'

export default {
  props: {
    // 书籍类型
    bookType: Array,
    // 书籍列表
    book: Array,
    // 判断是展开还是查看
    bookIndex: Number,
    imageUrl: String,
  },
  name: 'ReadingList',
  data() {
    return {
      tabsCunnerIndex: 0,
      bookTypeList: [],
      // 截取后的书籍排行
      spliceList: [],
      // 排行榜最大的长度
      bookTopLength: 0,
      isOpenClose: true,
      // 加载框
      loading: true,
    }
  },
  methods: {
    // 读书榜单排行榜
    getGoodsBook(value) {
      this.loading = true
      goodsBookTop({
        bookType: value,
        pageNo: 1,
        pageSize: 15,
      }).then((res_) => {
        console.log('读书榜单',res_.data)
        if (res_.code == 200) {
          this.loading = false
          this.bookTypeList = res_.data.list
          // 每次截取前5个数据
          // if (this.bookIndex == 1) {
          //   this.bookTopLength = this.bookTypeList.length
          //   this.spliceList = this.bookTypeList.splice(0, 5)
          // } else {
          //   this.spliceList = this.bookTypeList.splice(0, 10)
          // }
          this.spliceList =  this.bookTypeList
        } else {
          setTimeout(() => {
            this.loading = false
          }, 2000)
        }
      })
    },
    // tab切换
    tabsClick(index) {
      this.tabsCunnerIndex = index
      this.getGoodsBook(this.bookType[this.tabsCunnerIndex].value,1,10)
    },
    // 点击展开
    addBookTop() {
      if (this.spliceList.length < this.bookTopLength) {
        this.spliceList.push(...this.bookTypeList)
      } else {
        return false
      }
    },
    // 点击收起
    deleBookTop() {
      this.spliceList.splice(5, 5)
    },
    toBookReview() {
      console.log(1)
      this.$router.push({
        path: '/Teaching',
        query: { index: 1 },
      })
    }
  },
  watch: {
    bookType() {
      this.getGoodsBook(this.bookType[0].value, '1', '5')
    },
    spliceList() {
      if (this.bookIndex == 1) {
        if (this.spliceList.length >= this.bookTopLength) {
          this.isOpenClose = false
        } else {
          this.isOpenClose = true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.bookList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  router-link {
    text-decoration: none;
  }
  .item {
    margin-bottom: $mo-spacing-row-base;
    width: 160px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 500ms ease;
    text-decoration: none;
    .img {
      width: 100%;
      height: 210px;
      background: #fff;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;
      ::v-deep.el-image {
        width: 120px;
        height: 180px;
      }
      .tag {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        color: #fff;
        width: 20px;
        height: 20px;
        line-height: 18px;
        text-align: center;
      }
      .tag-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        border-right: 45px $mo-main-color solid;
        border-top: 35px transparent solid;
        z-index: 1;
      }
    }
    &:nth-child(2) .tag-bg {
      border-right-color: #ff4c15;
    }
    &:nth-child(3) .tag-bg {
      border-right-color: #ff8022;
    }
    .title {
      margin-top: 10px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #222222;
      line-height: 30px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
.rank-list {
  background: url('../../../assets/images/teaching/icon_74.png') no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 100%;
  box-shadow: -2px 6px 20px 0px rgba(215, 204, 204, 0.5);
  .tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    .tabs-item {
      color: #666;
      line-height: 50px;
      flex-grow: 1;
      text-align: center;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        display: block;
        height: 30px;
        width: 1px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #e1e1e1;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    .tabs-item:hover,
    .active {
      color: $mo-main-color;
      &:before {
        content: '';
        position: absolute;
        width: 60px;
        height: 4px;
        bottom: -2px;
        background: $mo-main-color;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .rank-list-inner {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    min-height: 100px;
    .item {
      line-height: 40px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      color: #666666;
      text-decoration: none;
      span:first-child {
        font-size: $mo-font-size-lg;
        font-weight: 600;
        color: #ff8420;
      }
      &:nth-child(1) span:first-child {
        color: #ca2222;
      }
      &:nth-child(2) span:first-child {
        color: #ff4c15;
      }
      &:nth-child(3) span:first-child {
        color: #ff8022;
      }
      &:hover {
        transform: scale(1.1);
        color: #ca2222;
      }
      span:last-child {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .rank-list-butt {
    border-top: 1px solid #e1e1e1;
    color: #888888;
    line-height: 50px;
    text-align: center;
    &:hover {
      color: $mo-main-color;
    }
  }
  .m-r {
    margin-right: 20px;
  }
}
</style>
