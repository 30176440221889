// 懒加载路由
const JournalismDetails = () => import('./journalismDetails.vue')

export default {
  path: '/journalismDetails',
  name: 'JournalismDetails',
  component: JournalismDetails,
  meta: {
    title: '新闻详情',
    authLogin: false //是否需要登录
  },
  children: []
}
