<template>
  <div class="journalism-item container pointer">
    <router-link
      class="journalism-item-inner"
      v-for="(detailItem, index) in detailList"
      :key="index"
      :to="{ path: '/JournalismDetails', query: { id: detailItem.id } }"
      target="_blank"
    >
      <div class="left">
        <div class="title">
          {{ detailItem.title }}
          <!-- 2020年8月26日，省委宣传部专门召开颁奖会议 -->
        </div>
        <div class="desc">
          {{ detailItem.content | contentRegx }}
          <!-- 2020年8月26日，省委宣传部专门召开颁奖会议，山西日报、山西电视台等十多家省内外新闻媒体给予报道。会上，省委常委、宣传部长吕岩松同志给项目负责人薛张华同志颁发了证书和奖牌，并号召相关部门对此项目予以支持，把这项公益活动在山西开展的更加扎实。2016年开展至今的4年时间里，项目已成功落地我省11个市、78个县（区）。 -->
        </div>
        <el-button type="danger" plain style="border: none" size="small">
          阅读全文
        </el-button>

        <div class="date">
          <span>{{ detailItem.noticeTime | date }}</span>
          <span>{{ detailItem.noticeTime | specificDate }}</span>
          <span></span>
        </div>
      </div>
      <div class="img">
        <el-image
          :src="`${imageUrl + detailItem.titleImage}`"
          fit="cover"
          lazy
          style="width: 100%;height: 100%;"
        ></el-image>
      </div>
    </router-link>
  </div>
</template>

<script>
import config from '@/utils/config'

export default {
  props: {
    detailList: {
      type: Array
    }
  },
  name: 'JournalismItem',
  data() {
    return {
      imageUrl: ''
    }
  },
  created() {
    this.imageUrl = config.imageUrl
  },
  filters: {
    date(value) {
      return value.substring(value.length - 2)
    },
    specificDate(value) {
      return value.substring(0, value.length - 3)
    },
    contentRegx(val) {
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      return valRegx8
    }
  }
}
</script>

<style scoped lang="scss">
div {
  //outline: 1px solid #4cd964;
}
  
.journalism-item-inner {
  router-link {
      text-decoration: none;
    }
  display: flex;
  &:hover {
    color: #CA2222;
    text-decoration: none;
    .desc {
      color: #CA2222 !important;
    }
    .date span{
      color: #CA2222 !important;
    }
  }
  .img {
    flex-shrink: 0;
    width: 312px;
    height: 188px;
  }
  .left {
    padding-left: 50px;
    border-top: 1px #535254 dotted;
    border-left: 1px #535254 dotted;
    margin-right: $mo-spacing-row-base;
    position: relative;
    height: 234px;

    .title {
      font-size: $mo-font-size-lg;
      font-weight: 600;
      line-height: 50px;
    }
    .desc {
      color: #333333;
      font-size: $mo-font-size-base;
      line-height: 37px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      height: 120px;
    }
    .el-button {
      position: absolute;
      right: 0px;
      bottom: 20px;
    }
    .date {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      span:first-child {
        font-size: 30px;
        font-family: DIN;
        font-weight: bold;
        color: #222222;
      }
      span:last-child {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #222222;
      }
    }
  }
}
</style>
