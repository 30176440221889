const Application = () => import('./Application.vue')
export default {
  path: '/Application',
  name: 'Application',
  component: Application,
  meta: {
    title: '志愿者申请',
    authLogin: false //是否需要登录
  },
  children: []
}