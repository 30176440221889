const ApplyDetails = () => import('./ApplyDetails.vue')
export default {
  path: '/ApplyDetails',
  name: 'ApplyDetails',
  component: ApplyDetails,
  meta: {
    title: '志愿者申请相关介绍',
    authLogin: false //是否需要登录
  },
  children: []
}

