<template>
  <div class="onex">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="desc">
          {{ptdOneXData.content}}
          <!-- <p>
            “山西省少年儿童1+X公益阅读计划”是一个立足学校阅读教育,关注儿童阅读成长的公益项目，可概括为三个结合:课题引领下的师资培训与阅读课堂教学相结合、课堂阅读与课外阅读相结合、课内外阅读与阅读活动相结合。
          </p>
          <p>
            “１＋Ｘ”的含义：一个决策、一个思想启发更多人思考；一个行为、一个行动带动更多人行动；一个校长带动一群老师读书；一个老师带动一群学生读书；一个学生影响一个家庭爱上读书；一个家庭带动周边乃至整个社会读书。
          </p> -->
        </div>
      </el-col>
      <el-col :span="12">
        <div class="imgList">
          <div class="imgList-inner">
            <div class="imfList-inner-" ref="imgList">
              <div class="img" v-for="(item, index) in httpImage" :key="index">
                <el-image
                  :src="item"
                  fit="cover"
                  style="width:240px;height:180px"
                  :preview-src-list="httpImage"
                ></el-image>
              </div>
            </div>
            <div class="block">
              <el-slider v-model="slideRvalue" :show-tooltip="false">
              </el-slider>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props:{
    ptdOneXData: Object,
    imageUrl: String
  },
  data() {
    return {
      slideRvalue: 0,
      httpImage: []
    }
  },
  watch: {
    slideRvalue() {
      if(this.httpImage.length < 2){
        this.$refs.imgList.style = `margin-left:-${0}px;`
      }else{
        let w = (200 * 10) / 400
        this.$refs.imgList.style = `margin-left:-${w * this.slideRvalue}px;`
      }
      
    },
    xImg() {
      this.clickTabs(this.cunnerIndex, this.xImg[this.cunnerIndex])
    },
    // 拼接图片
    ptdOneXData() {
      let imageArr = this.ptdOneXData.images.split(',')
      imageArr.filter( item => {
        this.httpImage.push(`${this.imageUrl + item}`)
      })
    }
  },
}
</script>

<style lang="scss" scope>
.onex {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  .desc {

      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
      text-indent: 1cm;
  }
  .imgList {
    width: 100%;
    display: flex;
    flex-direction: column;

    .imgList-inner {
      overflow-y: auto;
      width: 600px;

      &::-webkit-scrollbar {
        display: none;
      }

      .imfList-inner- {
        display: flex;
        flex-wrap: nowrap;
      }

      .img {
        flex-shrink: 0;
        width: 240px;
        height: 160px;
        overflow: hidden;
        margin: 10px;
        .el-icon-circle-close{
          color: #ca2222;
        }
        ::v-deep.el-image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .block {
      width: 90%;
      margin-left: 10px;
    }
  }
}
</style>