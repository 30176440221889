const ApplicationDetails = () => import('./ApplicationDetails.vue')
export default {
  path: '/ApplicationDetails',
  name: 'ApplicationDetails',
  component: ApplicationDetails,
  meta: {
    title: '志愿者申请相关介绍',
    authLogin: false //是否需要登录
  },
  children: []
}

