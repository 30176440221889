<template>
  <div class="book-review ">
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="12" v-for="(v,i) in bookReviewList" :key="i">
          <div class="item pointer">
            <div class="img">
              <img src="~images/home/icon_5.png" alt="" />
            </div>
            <div class="item-inner">
              <el-image :src="`${ imageUrl + v.serve.mainImage}`"></el-image>
              <div class="right">
                <div class="title">
                  {{v.serve.name}} 
                   <!-- 作者:[美]乔治.帕克 -->
                </div>
                <div class="review">
                  <div class="review-inner">
                    书评—{{v.member.name}}
                  </div>
                  <el-rate :value="parseInt(v.star)" disabled></el-rate>
                </div>
                <div class="content e-3" v-html="v.content">
                  <!-- 一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节点一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节
                  一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节点一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节
                  一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节点一部关于美国最近三四十年里的社会发展变迁历程的精彩非虚构文学作品。从1978年到2013年，作者在这三十五年时间，选择多个关键时间节 -->
                  <!-- <div class="look pointer" @click="openAll()">
                    【展开全部】
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 列表
    bookReviewList: Array,
    imageUrl: String
  },
  name: 'BookReview',
  data() {
    return {
      isAll: true
    }
  },
  methods: {
    openAll() {
      this.isAll = false
    }
  }
}
</script>

<style scoped lang="scss">
.book-review {
  .item {
    width: 590px;
    height: 208px;
    position: relative;
    margin-bottom: $mo-spacing-col-base;
    transition: all 500ms ease;
    &:hover {
      transform: scale(1.05);
    }
    .img {
      width: 100%;
      height: 100%;
    }
    .item-inner {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 20px;
      color: #3c3c3c;
      line-height: 30px;
      ::v-deep.el-image {
        width: 140px;
        height: 170px;
        flex-shrink: 0;
        margin-right: 20px;
      }
      .review {
        color: #222;
        font-size: $mo-font-size-lg;
        display: flex;
        align-items: center;
        .review-inner {
          margin-right: $mo-spacing-row-base;
        }
      }
      .content {
        height: 90px;
        position: relative;
        .look {
          position: absolute;
          right: 0;
          background: #f1f0ef;
          color: $mo-main-color;
          bottom: 0;
        }
      }
    }
  }
}
</style>
