<template>
  <div class="Advantage">
    <div class="Advantage-box e-4"
         v-for="(item, index) in advantage"
         :key="index">
      <div class="image">
        <el-image style="width:224px;height:180px"
                  :src="`${imageUrl + item.image}`"
                  fit="fill">
        </el-image>
      </div>
      <div class="icon-triangle"></div>
      <p class="content">
        {{ item.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    advantage: Array,
    imageUrl: String
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scope>
.Advantage {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(245, 247, 249, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(245, 247, 249, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .Advantage-box {
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
    &:hover {
      transform: scale(1.05);
    }
    .icon-triangle {
      margin: 10px 0;
      width: 10px;
      border: 8px solid transparent;
      border-top-color: #ca2222;
      border-bottom: none;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
      width: $mo-main-width-lg/5 - 20;
    }
  }
}
</style>
