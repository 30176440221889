<template>
  <div>
    <keep-alive
      ><AppHeader @index="tabIndex" :default-index="4"></AppHeader
    ></keep-alive>
    <Banner :list="bannerList"></Banner>
    <div class="book-container">
      <div v-if="index == 0 || index == 1 || index == 2" class="title">
        <div :class="['img', index == 1 ? 'book-img' : '']">
          <img
            :src="
              require(index == 0
                ? '../../assets/images/teaching/icon_64.png'
                : index == 1
                ? '../../assets/images/teaching/icon_70.png'
                : '../../assets/images/teaching/icon_72.png')
            "
            alt=""
          />
        </div>
        <span>
          {{
            index == 0
              ? '优秀书评'
              : index == 1
              ? '图书借阅'
              : index == 2
              ? '公益课程'
              : ''
          }}</span
        >
      </div>
      <!-- 优秀书评开始 -->
      <div v-show="index == 0">
        <BookReview
          :bookReviewList="bookReview"
          :imageUrl="imageUrl"
        ></BookReview>
        <div class="pagination">
          <el-pagination
            @current-change="ExcellentListChange"
            background
            layout="prev, pager, next"
            :total="parseInt(excellentTotal.count)"
            :page-size="10"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 优秀书评结束 -->
      <!-- 读书榜单开始 -->
      <ReadingList
        v-show="index == 1"
        :book="bookList"
        :imageUrl="imageUrl"
        :bookType="bookTypeList"
        :bookIndex="parseInt(index)"
      ></ReadingList>
      <!-- 读书榜单结束 -->
      <!-- 公益课程开始 -->
      <el-row :gutter="40" v-show="index == 2">
        <el-col :span="6" v-for="(v, i) in welfareList" :key="i">
          <CourseItem :item="v" :imageUrl="imageUrl"></CourseItem>
        </el-col>
        <div class="pagination">
          <el-pagination
            @current-change="VideoListChange"
            background
            layout="prev, pager, next"
            :total="parseInt(videoTotal.count)"
            :page-size="30"
          >
          </el-pagination>
        </div>
      </el-row>
      <!-- 公益课程结束 -->
      <!-- 阅读培训开始 -->
      <ReadingTraining :trainingContent="trainingContent" v-if="index == 3" />
      <!-- 阅读培 训结束 -->
      <!-- 校长读书会开始 -->
      <ReadingTraining :trainingContent="trainingContent" v-if="index == 4" />
      <!-- 校长读书会结束 -->
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import ReadingList from '@/components/content/reading/ReadingList'
import CourseItem from '@/components/content/teaching/CourseItem'
import BookReview from '@/components/content/teaching/BookReview'
import ReadingTraining from '@/components/content/teaching/ReadingTraining'

import {
  bannerDetail,
  videoList,
  hotList,
  excellentList,
  dictGrade,
  readTrainingInfo,
  principalReadingInfo,
} from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    Banner,
    BookReview,
    ReadingList,
    CourseItem,
    AppFooter,
    ReadingTraining,
  },
  data() {
    return {
      index: 0,
      indexType: 0,
      imageUrl: '',
      bannerList: [],
      welfareList: [], // 公益课程
      bookList: [], // 读书榜单
      bookReview: [], // 优秀书评
      videoTotal: {}, // 公益课程总条数
      excellentTotal: {}, // 优秀书评总条数
      bookTypeList: [], // 排行榜tab
      trainingContent: {
        title: '',
        content: '',
        isLink: false,
      }, // 阅读培训 || 校长读书会
    }
  },
  created() {
    this.imageUrl = config.imageUrl
    // 轮播图
    this.index = this.$route.query.index
    this.getBanner()
    // 优秀书评
    this.getExcellentList('1')
    // 公益课程
    this.getVideoList('1')
    // 读书榜单
    hotList({
      property: '1',
      pageNo: '1',
      pageSize: '15',
    }).then((res_) => {
      this.bookList = res_.data.list
    })
    // 读书榜单排行榜
    dictGrade().then((res_) => {
      this.bookTypeList = res_.data.bookType
    })
  },
  methods: {
    tabIndex(i) {
      this.index = i
      this.getBanner()
    },
    getBanner() {
      if (this.index == 0) {
        this.indexType = 6
        bannerDetail({
          type: '6',
        }).then((res_) => {
          this.bannerList = res_.data
        })
      } else if (this.index == 1) {
        bannerDetail({
          type: '7',
        }).then((res_) => {
          this.bannerList = res_.data
        })
      } else if (this.index == 2) {
        bannerDetail({
          type: '8',
        }).then((res_) => {
          this.bannerList = res_.data
        })
      } else if (this.index == 3) {
        bannerDetail({
          type: '10',
        }).then((res_) => {
          this.bannerList = res_.data
        })
      } else if (this.index == 4) {
        bannerDetail({
          type: '11',
        }).then((res_) => {
          this.bannerList = res_.data
        })
      }
    },
    // 公益课程
    getVideoList(val) {
      videoList({
        property: '2',
        pageNo: val,
        pageSize: 30,
        isPublic: '1',
      }).then((res_) => {
        this.videoTotal = res_.data
        this.welfareList = res_.data.list
      })
    },
    // 优秀书评
    getExcellentList(val) {
      excellentList({
        pageNo: val,
        pageSize: '10',
      }).then((res_) => {
        console.log(res_)
        this.excellentTotal = res_.data
        this.bookReview = res_.data.list
      })
    },
    // 公益课程页数切换
    VideoListChange(val) {
      this.getVideoList(val)
    },
    // 优秀书评页数切换
    ExcellentListChange(val) {
      this.getExcellentList(val)
    },
  },
  watch: {
    index() {
      if (this.index == 3) {
        // 阅读培训
        readTrainingInfo().then((res) => {
          console.log('res', res)
          this.trainingContent = res.data
        })
      } else if (this.index == 4) {
        // 校长读书会
        principalReadingInfo().then((res) => {
          console.log('res', res)
          this.trainingContent = res.data
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.book-container {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 20px;
    span {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      vertical-align: middle;
    }
    .img {
      width: 24px;
      height: 25px;
      display: inline-block;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .book-img {
      width: 47px;
      height: 23px;
      display: inline-block;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .book-item {
    display: flex;
    flex-wrap: wrap;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  ::v-deep.active {
    background: #f52021 !important;
  }
}
</style>