<template>
  <div class="custom-header">
    <div class="app-header-bott">
      <!--      <el-header height="50px">-->
      <div class="container">
        <div class="app-header-bott-inner">
          <div class="item" v-for="(v, i) in tabsList" :key="i">
            <div
              :class="[cunnerIndex == i ? 'active' : '', 'item-inner']"
              @click="clickTabs(v, i)"
            >
              <i class="iconfont" :class="[v.icon]"></i>
              {{ v.title }}
              <i class="el-icon-arrow-down" v-if="v.children"></i>
            </div>
            <div
              :class="[
                'item-children',
                cunnerIndex == i ? 'children-active' : '',
              ]"
              v-if="v.children"
            >
              <div
                class="item-children-inner"
                v-for="(item, index) in v.children"
                :key="index"
                @click="tabsCilck(item, index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      </el-header>-->
    </div>
    <div class="app-notice" v-if="notice">
      <div class="container">
        <div class="app-notice-inner">
          <div class="app-notice-inner-title">最新<span>公告</span></div>
          <vue-seamless-scroll
            :data="appNotice"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item pointer"
                v-for="(item, index) in appNotice"
                :key="index"
                @click="toDetail(item.id)"
              >
                {{ item.title }}
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'AppHeader',
  props: {
    defaultIndex: {
      type: Number,
      default: 0,
    },
    notice: Boolean,
    appNotice: Array,
  },
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      searchText: '',
      tabsList: [
        {
          title: '首页',
          link: '/Home',
          icon: 'iconshouye',
        },
        {
          title: '项目介绍',
          link: '/ProjectIntroduction',
          icon: 'iconbiaoqian',
        },
        {
          title: '新闻通告',
          link: '/Journalist',
          icon: 'iconxinwen',
        },
        {
          title: '会员单位',
          link: '/Cooperation',
          icon: 'icondanwei',
          children: [
            {
              title: '合作院校',
              link: '/Cooperation',
              icon: '',
            },
            {
              title: '合作企业',
              link: '/Cooperation',
              icon: '',
            },
          ],
        },
        {
          title: '教研中心',
          link: '/Teaching',
          icon: 'iconjiaoyanben',
          children: [
            {
              title: '优秀书评',
              link: '/Teaching',
              icon: '',
            },
            {
              title: '图书借阅',
              link: '/Teaching',
              icon: '',
            },
            {
              title: '公益课程',
              link: '/Teaching',
              icon: '',
            },
            {
              title: '阅读培训',
              link: '/Teaching',
              icon: '',
            },
            {
              title: '校长读书会',
              link: '/Teaching',
              icon: '',
            },
          ],
        },
        {
          title: '志愿者',
          link: '/Volunteer',
          icon: 'iconzhiyuanzhe',
        },
      ],
      cunnerIndex: 0,
      classOption: {
        limitMoveNum: 1,
        direction: 2,
        step: 0.5,
      },
    }
  },
  methods: {
    tabsCilck(v, i) {
      if (this.defaultIndex == 3) {
        this.cunnerIndex = 3
      } else if (this.defaultIndex == 4) {
        this.cunnerIndex = 4
      } else {
        this.cunnerIndex = i
      }
      this.$router.push({
        path: v.link,
        query: { index: i },
      })
      this.$emit('index', i)
    },
    clickTabs(v, i) {
      this.cunnerIndex = i
      if (v.children != undefined) {
        this.$router.push({
          path: v.link,
          query: { index: 0 },
        })
      } else {
        this.$router.push({
          path: v.link,
        })
      }
    },
    toDetail(id) {
      this.$router.push({
        path: '/journalismDetails',
        query: { id: id },
      })
    },
  },
  mounted() {
    this.cunnerIndex = this.defaultIndex
  },
  watch: {},
}
</script>



<style scoped lang="scss">
$heaserHight: 78px;


.warp {
  width: 100%;
  height: 50px;
  overflow: hidden;
  margin: 0 $mo-spacing-row-base;
  .ul-item {
    display: flex;
    width: 100%;
    .li-item {
      white-space: nowrap;
      letter-spacing: 2px;
      color: #666666;
      font-family: Microsoft YaHei;
      margin-left: 20px;
      &:hover {
        color: $mo-main-color;
      }
    }
  }
}

.custom-header {
  width: 100%;

  .app-header-bott {
    height: 50px;
    background: $mo-main-color;

    .app-header-bott-inner {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      background-color: $mo-main-color;

      .item {
        cursor: pointer;
        width: 130px;
        height: 100%;
        line-height: 50px;
        color: #fff;
        font-size: $mo-font-size-lg;
        text-align: center;
        z-index: 10;
        i {
          transition: all 1s ease;
        }
        &:first-child {
          .item-inner {
            height: 65px;
            background: #e94d4d;
            transform: translateY(-15px);
            line-height: 65px;
            position: relative;

            &::after {
              position: absolute;
              content: '';
              border-left: 15px solid #9a1414;
              border-top: 15px solid transparent;
              right: -15px;
              top: 0;
            }
          }
        }

        .item-children {
          display: none;
          background: #fff;
          color: $mo-text-color;
          font-size: $mo-font-size-base;
          box-shadow: 0 4px 10px 0 rgba(132, 132, 132, 0.6);

          .item-children-inner {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              background: #e1e1e1;
              height: 1px;
              width: 60%;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }

        &:hover {
          .item-inner {
            background: #e94d4d;

            .el-icon-arrow-down {
              transform: rotate(-180deg);
            }
          }

          .item-children {
            display: block;
          }
        }

        .active {
          background: #e94d4d;
        }
      }
    }
  }

  .app-notice {
    background: #f3f3f3;
    height: 50px;
    line-height: 50px;

    .app-notice-inner {
      display: flex;
      background: #f3f3f3;
      .app-notice-inner-title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 2px;
        flex-shrink: 0;
        span {
          color: $mo-main-color;
        }
      }
    }
  }
}

@keyframes active {
  0% {
    width: 0;
  }
  60% {
    width: 20px;
  }
  100% {
    width: 40px;
  }
}
</style>
