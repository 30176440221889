<template>
  <div class="courseDetails">
    <AppHeader :default-index="4"></AppHeader>
    <div class="courseDetails-header">
      <div class="title">
        <div class="space">
          <span class="classes">读书榜单</span>
          <span class="m-10 classes">></span>
          <span class="primary">{{ bookDetail.name }}</span>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 图片 -->
      <el-row :gutter="60">
        <el-col :span="6">
          <div class="img">
            <el-image
              :src="`${imageUrl + bookDetail.mainImage}`"
              lazy
              fit="cover"
            ></el-image>
          </div>
        </el-col>
        <!-- 课程 -->
        <el-col :span="18">
          <div class="introduction">
            <h3 class="curriculum">{{ bookDetail.name }}</h3>
            <span class="sp ter">{{ bookDetail.remarks || '' }}</span>
            <div class="sp">
              <img src="../../assets/images/bookStore/icon_74.png" alt="" />
              浏览人数：{{ bookDetail.scanNum }}
            </div>
            <div class="sp">
              <img src="../../assets/images/bookStore/icon_73.png" alt="" />
              适合年龄段：<span class="age">{{
                bookDetail.goodsCategory && bookDetail.goodsCategory.name
              }}</span>
            </div>
            <div class="borrowing pointer">
              借阅
              <div class="bullet-frame">
                <div class="image">
                  <el-image :src="require('../../assets/images/home/icon_14.jpg')" style="width:124px" fit="fill"></el-image>
                </div>
                <div class="scan-wechat">
                  <p>微信扫一扫</p>
                  <p>至<span>图书小程序</span>内借阅</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="details">
        <span>图书介绍</span>
      </div>
    </div>
    <div class="main p-20">
      <div class="dataContent" v-html="bookDetail.content"></div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import { goodsDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      goodsData: {},
      imageUrl: '',
      bookDetail: {}
    }
  },
  name: 'bookStoreDetails',
  created() {
    this.imageUrl = config.imageUrl
    goodsDetail({
      goodsId: this.$route.query.id
    }).then(res_ => {
      this.bookDetail = res_.data.goods
      console.log(res_)
    })
  },
}
</script>

<style scoped lang="scss">
.courseDetails {
  &-header {
    height: 60px;
    width: 100%;
    background: #eef5ff;
    .title {
      width: 1200px;
      margin: 0 auto;
      .space {
        position: relative;
        .classes {
          color: #222222;
          font-size: 18px;
          line-height: 60px;
          margin-left: 20px;
        }
        .primary {
          color: $mo-main-color;
          font-size: 18px;
          line-height: 60px;
          margin-left: 10px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -11px;
          width: 8px;
          height: 22px;
          background: $mo-main-color;
          display: inline-block;
        }
        .m-10 {
          margin-left: 15px;
        }
      }
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    .img {
      height: 290px;
      overflow: hidden;
      width: 100%;
      .el-image {
        height: 100%;
        width: 100%;
        transition: all 500ms ease;
      }
    }
    .introduction {
      .curriculum {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }

      .sp {
        font-size: 16px;
        font-weight: 400;
        color: #222;
        margin-top: 20px;
        img {
          width: 15px;
          height: 13px;
          margin: 0 5px;
        }
        .age {
          padding: 5px;
          border-radius: 5px;
          border: 1px solid $mo-main-color;
          color: $mo-main-color;
        }
      }
      .ter {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 1.8;
      }
      .borrowing {
        margin-top: 30px;
        display: inline-block;
        padding: 15px 50px;
        background: $mo-main-color;
        color: #fff;
        position: relative;
        &:hover {
          .bullet-frame  {
            display: block;
          }
        }
        .bullet-frame {
          display: none;
          position: absolute;
          right: -150%;
          top: -100%;
          padding: 20px;
          background: #ffffff;
          box-shadow: 0px 0px 16px 0px rgba(204, 204, 204, 0.6);
          border-radius: 4px;
        }
        .scan-wechat {
          margin-top: 20px;
          p {
            color: #222;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            span {
              color: #1E58FF;
            }
          }
        }
      }
    }
    .dataContent {
      font-size: 16px;
      color: #666666;
      line-height: 1.8;
    }
  }
  .details {
    width: 1200px;
    margin: 0 auto;
    span {
      margin-top: 40px;
      border: 1px solid $mo-main-color;
      padding: 10px 20px;
      display: inline-block;
      border-radius: 4px;
      color: $mo-main-color;
    }
  }
  .p-20 {
    padding-top: 0;
  }
}
</style>
