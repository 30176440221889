<template>
  <div class="main">
    <div class="img">
      <img src="~assets/images/cooperationSchool/icon_55.png" alt="" />
    </div>
    <div class="cooperation">
      <div class="title">
        <span class="school">合作学校</span>
      </div>
      <el-row :gutter="20" class="m-t40">
        <el-col
          class="m-b20"
          :span="12"
          v-for="(item, index) in propData"
          :key="index"
        >
          <div class="right" @click="toOuter(item.url)">
            <div class="el-image">
              <el-image
                :src="`${imageUrl + item.image}`"
                fit="fill"
              ></el-image>
            </div>
            <div class="content">
              <div class="schoolName">
                <span>{{ item.name }}</span>
              </div>
              <div class="space">
                <div class="addres-tel">
                  <img
                    src="~assets/images/cooperationSchool/icon_56.png"
                    alt=""
                  />
                  <span>地址：{{ item.site }}</span>
                </div>
                <div class="addres-tel">
                  <img
                    src="~assets/images/cooperationSchool/icon_57.png"
                    alt=""
                  />
                  <span>联系方式：{{ item.tel }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propData: Array,
    imageUrl: String
  },
  data() {
    return {
      
    }
  },
  methods: {
    toOuter(link) {
      if(link){
        window.open(`http://${link}`,'_black')
      }else{
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  position: relative;
  .img {
    width: 646px;
    height: 400px;
    position: absolute;
    top: -20px;
    left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cooperation {
    padding-top: 50px;
    padding-bottom: 90px;
    width: 1200px;
    margin: 0 auto;
    .m-t40 {
      margin-top: 40px;
      .m-b20 {
        margin-bottom: 20px;
      }
    }
    .title {
      width: 368px;
      height: 28px;
      background: #ffffff;
      border: 1px solid rgba(202, 34, 0, 0.3);
      margin: 0 auto;
      text-align: center;
      .school {
        font-size: 22px;
        font-weight: 600;
        color: #222222;
        padding: 0 10px;
        background: #fff;
        transform: translatey(-15px);
        display: inline-block;
      }
    }
    .right {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      display: flex;
      align-items: center;
      padding: 15px;
      background: #f5f7f9;
      border-radius: 4px;
      .el-image {
        width: 134px;
        height: 134px;
        margin-right: 30px;
        el-image {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        .schoolName {
          font-size: 18px;
          font-weight: 600;
          color: #222222;
          &::after {
            content: '';
            margin-top: 6px;
            display: block;
            width: 29px;
            height: 3px;
            background: #ca2222;
            border-radius: 2px;
          }
        }
        .space {
          margin-top: 20px;
          .addres-tel {
            font-size: 14px;
            color: #3c3c3c;
            margin-bottom: 15px;
            img {
              width: 12px;
              height: 14px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>