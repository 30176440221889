<template>
  <div class="tabs">
    <div
      class="tebs-item"
      v-for="(item, index) in list"
      :key="index"
      :class="{ active: index == cunnerIndex }"
      @click="clickTabs(index)"
    >
      <i class="iconfont" :class="[item.icon]"></i>
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      list: [
        {
          title: '最新公告',
          icon: 'icongonggao'
        },
        {
          title: '公益阅读活动',
          icon: 'iconhuodong'
        },
        {
          title: '政策解读',
          icon: 'iconziyuan'
        }
      ],
      cunnerIndex: 0
    }
  },
  methods: {
    clickTabs(index) {
      this.cunnerIndex = index
      this.$emit('change', index)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  margin-left: $mo-spacing-row-base;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999;
  .tebs-item {
    cursor: pointer;
    line-height: 60px;
    font-size: $mo-font-size-lg;
    margin-right: $mo-spacing-row-lg;
    font-weight: 600;
    position: relative;
  }
  .tebs-item:hover,
  .active {
    color: $mo-main-color;
    &:before {
      content: '';
      position: absolute;
      height: 3px;
      background: $mo-main-color;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 70px;
    }
    &:after {
      content: '';
      position: absolute;
      border: 7px solid transparent;
      border-bottom-color: $mo-main-color;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
