<template>
  <div id="app">
    <div class="app-header-top container">
      <div class="app-header-top-inner">
        <div class="logo">
          <div class="icon">
            <a href="/"></a>
            <img src="~images/home/icon_10.png" alt="" />
          </div>
          <div class="logo-left">
            <div class="logo-left-t">山西省少年儿童1+X公益阅读网</div>
            <a
              class="logo-left-b"
              href="//www.rtprpc.com"
              target="_blank"
              @click.stop
              >www.rtprpc.com</a
            >
          </div>
        </div>
        <div class="weather">
          <div id="he-plugin-standard"></div>
        </div>
        <div class="qrcode" v-if="true">
          <el-image
            :src="require('../src/assets/images/home/icon_14.jpg')"
            style="width:100px;height:100px"
            fit="fill"
          >
          </el-image>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
/* 引入全局样式 */
@import 'assets/scss/common.scss';

#app {
  width: 100%;
  height: 100%;
}

.app-header-top {
  display: flex;
  justify-content: space-between;
  height: 120px;
  align-items: center;

  .app-header-top-inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .qrcode{

  }

  .logo {
    display: flex;
    align-items: center;

    .icon {
      width: 60px;
      height: 60px;
      position: relative;
      margin-right: $mo-spacing-row-sm;

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .logo-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ca2222;

      &-t {
        font-size: 25px;
        font-weight: 600;
      }

      &-b {
        font-size: 18px;
        color: #ca2222;
        line-height: 25px;
      }
    }
  }

  .line {
    width: 1px;
    background: #e6e6e6;
    height: 60px;
    margin: 0 $mo-spacing-row-base;
  }

  .weather {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: $mo-font-size-base;
    .wv-lt-refresh {
      display: none;
    }
    .weather-inner {
      display: flex;
      color: #666;
      line-height: 30px;

      .weather-inner-con {
        margin-left: $mo-spacing-row-sm;

        span {
          color: #0c75f7;
        }
      }
    }
  }

  .search {
    width: 372px;

    ::v-deep.el-button--default {
      border: 1px solid $mo-main-color;
      background: $mo-main-color;
      color: #fff;
      border-radius: 0;
    }
  }
}
</style>
