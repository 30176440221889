const Teaching = () => import('./Teaching.vue')
export default {
  path: '/Teaching',
  name: 'Teaching',
  component: Teaching,
  meta: {
    title: '教研中心',
    authLogin: false //是否需要登录
  },
  children: []
}

