}
<template>
  <div class="banner">
    <el-carousel indicator-position="none">
      <el-carousel-item v-for="(item, i) in list" :key="i">
        <div class="banner-inner">
          <el-image
            :src="`${imageUrl + item.image}`"
            fit="fill"
          ></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import config from '@/utils/config'

export default {
  props: {
    list: {
      type: Array,
      default: () => [
        {
          img: ''
        },
        {
          img: ''
        },
        {
          img: ''
        }
      ]
    }
  },
  name: 'Banner',
  data() {
    return {
      imageUrl:''
    }
  },
  created(){
    this.imageUrl = config.imageUrl
  }
}
</script>

<style scoped lang="scss">
.banner-inner {
  position: relative;
  ::v-deep.el-image {
    width: 100%;
    height: 100%;
  }
  .title {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    .title-inner {
      color: #fff;
      font-size: $mo-font-size-lg;
      line-height: 60px;
      padding: 0 20px;
    }
  }
}
</style>
