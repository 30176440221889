<template>
  <div class="courseDetails">
    <AppHeader :default-index="4"></AppHeader>
    <div class="courseDetails-header">
      <h3 class="title">
        <div class="space">
          <span class="classes">公益课程</span>
          <span class="m-10 classes">></span>
          <span class="primary">{{ videoDetails.name }}</span>
        </div>
      </h3>
    </div>
    <div class="main">
      <!-- 图片 -->
      <h3 class="terName">
        {{ videoDetails.teacher }}：{{ videoDetails.name }}
      </h3>
      <el-row :gutter="20">
        <el-col :span="11">
          <video
            class="pointer"
            :src="`${imageUrl + videoDetails.video}`"
            controls
            width="100%"
            height="300px"
            :poster="`${imageUrl + videoDetails.mainImage}`"
          ></video>
          <!-- <div class="img">
            <el-image
              :src="`${imageUrl + videoDetails.mainImage}`"
              lazy
              fit="cover"
            ></el-image>
          </div> -->
        </el-col>
        <!-- 课程 -->
        <el-col :span="11" class="position-introduction">
          <div class="introduction">
            <div class="flex">
              <div class="lecturer">
                <div class="image">
                  <el-image :src="imageArr.image1"></el-image>
                </div>
                <span>讲师：{{ videoDetails.teacher }}</span>
              </div>
              <div class="lecturer">
                <div class="image">
                  <el-image :src="imageArr.image2"></el-image>
                </div>
                <span>节数：{{ videoDetails.pitchNumber }}</span>
              </div>
              <div class="lecturer">
                <div class="image">
                  <el-image :src="imageArr.image3"></el-image>
                </div>
                <span>授课语言：{{ videoDetails.courseType }}</span>
              </div>
              <div class="lecturer">
                <div class="image">
                  <el-image :src="imageArr.image4"></el-image>
                </div>
                <span>年级：{{ videoDetails.grade }}</span>
              </div>
            </div>
            <div :class="['brief', 'm-height']" v-if="videoDetails.remarks">
              <h3 class="title">课程简介</h3>
              <p :class="!isOpenAll ? 'e-3' : ''">{{ videoDetails.remarks }}</p>
              <div
                v-if="videoDetails.remarks.length > 113"
                class="openAll pointer"
                @click="!isOpenAll ? openAll() : closeAll()"
              >
                {{ !isOpenAll ? '【展开全部】' : '【收起全部】' }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 详情 -->
      <div class="details">
        <span>课程详情</span>
      </div>
    </div>
    <div class="main p-20">
      <div class="dataContent" v-html="videoDetails.content"></div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import { goodsDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      videoDetails: {},
      imageUrl: '',
      imageArr: {
        image1: require('../../assets/images/teaching/icon_76.png'),
        image2: require('../../assets/images/teaching/icon_75.png'),
        image3: require('../../assets/images/teaching/icon_78.png'),
        image4: require('../../assets/images/teaching/icon_77.png'),
      },
      isOpenAll: false,
      content:
        '公益五十课，“五十课，教你学会做公益”。由福特基金会资助，中国公益研究院（CPRI）实施的“公益慈善网络公开课”项目面向广大有志于进入公益慈善行业的志愿者、大学生和社会公众，集结国内公益慈善领域以及非营利组织和机构资深公益五十',
    }
  },
  name: 'CourseDetails',
  created() {
    this.imageUrl = config.imageUrl
    goodsDetail({
      goodsId: this.$route.query.id,
    }).then((res_) => {
      this.videoDetails = res_.data.goods
    })
  },
  methods: {
    openAll() {
      this.isOpenAll = true
    },
    closeAll() {
      this.isOpenAll = false
    },
  },
}
</script>

<style scoped lang="scss">
.courseDetails {
  video {
    outline: none;
  }
  &-header {
    height: 60px;
    width: 100%;
    background: #eef5ff;
    .title {
      width: 1200px;
      margin: 0 auto;
      .space {
        position: relative;
        .classes {
          color: #222222;
          font-size: 18px;
          line-height: 60px;
          margin-left: 20px;
        }
        .primary {
          color: $mo-main-color;
          font-size: 18px;
          line-height: 60px;
          margin-left: 15px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -11px;
          width: 8px;
          height: 22px;
          background: $mo-main-color;
          display: inline-block;
        }
        .m-10 {
          margin-left: 15px;
        }
      }
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    .terName {
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 600;
      color: #333333;
    }
    .img {
      height: 290px;
      overflow: hidden;
      width: 100%;
      .el-image {
        height: 100%;
        width: 100%;
        transition: all 500ms ease;
      }
    }

    .position-introduction {
      position: relative;
      .introduction {
        min-height: 300px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 20px 40px 20px;
        background: #f5f7f9;
        .flex {
          display: flex;
          flex-wrap: wrap;
          .lecturer {
            width: 50%;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            display: flex;
            align-items: center;
            span {
              margin-left: 20px;
            }
          }
        }
      }
    }

    .dataContent {
      font-size: 16px;
      color: #666666;
      line-height: 1.8;
    }
    .brief {
      overflow: hidden;
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        line-height: 34px;
      }
      .openAll {
        color: #ca2222;
        position: absolute;
        right: 20px;
        bottom: 12px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .m-height {
      max-height: 120px;
    }
  }
  .details {
    width: 1200px;
    margin: 0 auto;
    span {
      margin-top: 40px;
      border: 1px solid $mo-main-color;
      padding: 10px 20px;
      display: inline-block;
      border-radius: 4px;
      color: $mo-main-color;
    }
  }
  .p-20 {
    padding-top: 0;
  }
}
</style>
