<template>
  <div class="volunteer">
    <div class="top-img pointer" @click="toLink('/Volunteer')">
      <img src="~images/home/icon_2.png" alt="" />
    </div>
    <div class="desc">
      <div class="desc-inner e-4">
        为了不断地壮大公益志愿者服务队伍，提高服务水平，更好地服务在校学生，助推名师工程建设，家校合作共育我们现面向广大社会人士招募公益阅读推广志愿者，呼吁广大热心公益事业，自愿贡献个人时间和精力加入到志愿者队伍中来。在服务社会、学生的同时，提高自我，发展自我。
        为了不断地壮大公益志愿者服务队伍，提高服务水平，更好地服务在校学生，助推名师工程建设，家校合作共育我们现面向广大社会人士招募公益阅读推广志愿者，呼吁广大热心公益事业，自愿贡献个人时间和精力加入到志愿者队伍中来。在服务社会、学生的同时，提高自我，发展自我。
        为了不断地壮大公益志愿者服务队伍，提高服务水平，更好地服务在校学生，助推名师工程建设，家校合作共育我们现面向广大社会人士招募公益阅读推广志愿者，呼吁广大热心公益事业，自愿贡献个人时间和精力加入到志愿者队伍中来。在服务社会、学生的同时，提高自我，发展自我。
      </div>
      <div class="tag-inner">
        <div class="tag e-1">志愿者招募条件/志愿者待遇/志愿者工作范围...</div>
        <el-button plain type="danger" round size="mini" @click="toLink('/Volunteer')"
          >查看更多 <i class="el-icon-d-arrow-right"></i
        ></el-button>
      </div>
    </div>
    <div class="entrance">
      <div class="item" @click="toLink('Apply')">
        <div class="img">
          <img src="~images/home/icon_3.png" alt="" />
        </div>
        <div class="item-con">
          阅读导师志愿者申请入口
        </div>
      </div>
      <div class="item" @click="toLink('Application')">
        <div class="img">
          <img src="~images/home/icon_4.png" alt="" />
        </div>
        <div class="item-con">
          <div>家长阅读志愿者申请入口</div>
          <div>(小学生家长)</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Volunteer',
  methods: {
    toLink(url){
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.volunteer {
  width: 100%;
  height: 420px;

  .top-img {
    height: 90px;

    img {
      object-fit: cover;
    }
  }

  .desc {
    height: 210px;
    color: #3c3c3c;
    background: #f3f6f8;
    margin-top: 20px;
    padding: 10px $mo-spacing-row-base $mo-spacing-row-base $mo-spacing-row-base;
    line-height: 35px;
    position: relative;

    .desc-inner {
      height: 140px;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      border-color: transparent transparent #f3f6f8 transparent;
      border-style: solid;
      border-width: 0 20px 20px 20px;
      transform: rotate(135deg);
      top: -10px;
      left: 30%;
    }

    .tag-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tag {
        width: 100%;
        margin-right: $mo-spacing-row-base;
        color: $mo-main-color;
      }
    }
  }

  .entrance {
    display: flex;
    justify-content: space-between;
    margin-top: $mo-spacing-row-base;

    .img {
      height: 70px;
      width: 260px;
    }

    .item {
      cursor: pointer;
      position: relative;
      transition: all 1s ease;

      .item-con {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        flex-direction: column;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
