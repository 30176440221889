<template>
  <div class="banner">
    <el-carousel height="360px" indicator-position>
      <el-carousel-item v-for="(item, i) in list" :key="i" >
        <div class="banner-inner">
          <el-image :src="`${imageUrl + item.image}`" fit="cover"></el-image>
          <div class="title">
            <div class="title-inner e-1">
              {{ item.name }}
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  props: {
    list: Array,
    imageUrl: String
  },
  name: 'Banner',
  data() {
    return {
      
    }
  }
}
</script>

<style scoped lang="scss">

.banner-inner {
  position: relative;
  height: 360px;
  ::v-deep.el-image {
    width: 100%;
    height: 100%;
  }
  .title {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    .title-inner {
      color: #fff;
      font-size: $mo-font-size-lg;
      line-height: 60px;
      padding: 0 20px;
    }
  }
}
</style>
