<template>
  <div class="course-item">
    <router-link
      :to="{ path: '/CourseDetails', query: { id: item.id } }"
      target="_blank"
      class="course-item-inner"
    >
      <div class="img">
        <el-image
          :src="`${imageUrl + item.mainImage}`"
          lazy
          fit="cover"
        ></el-image>
        <div class="playbutton">
          <el-image
            :src="require('../../../assets/images/teaching/icon_20.png')"
            lazy
            fit="cover"
          ></el-image>
        </div>
      </div>
      <div class="title">
        {{ item.name }}
      </div>
      <div class="desc">{{ item.teacher }} | {{ item.pitchNumber }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    // 课程
    item: Object,
    imageUrl: String,
  }
}
</script>

<style scoped lang="scss">
.course-item {
  padding-bottom: $mo-spacing-col-lg;
}
.course-item-inner {
  text-decoration: none;
  cursor: pointer;
  .img {
    width: 270px;
    height: 190px;
    border-radius: $mo-border-radius-lg;
    position: relative;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
      transition: all 500ms ease;
    }
    .playbutton {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
    }
  }
  .title {
    font-size: $mo-font-size-lg;
    margin-top: $mo-spacing-row-sm;
    font-weight: 600;
    line-height: 30px;
  }
  .desc {
    font-size: $mo-font-size-base;
    color: $mo-text-color-grey;
  }
  &:hover {
    .el-image {
      transform: scale(1.1);
    }
    color: $mo-main-color;
    .desc {
      color: $mo-main-color;
    }
  }
}
</style>
