<template>
  <div class="nature-container">
    <el-row>
      <el-col :span="8">
        <div class="elImage">
          <el-image
            :src="
              require('../../../assets/images/projectIntroduction/icon_32.png')
            "
            fit="fill"
          ></el-image>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-list">
          <ul>
            <li v-for="(item, index) in natureData" :key="index" :class="index % 2 == 1 ? 'sort-iconcolor' : 'sort-icon'">
              <div :class="['slthover', item.isEnable == 1 ? 'slthoverImprot' : '']">
                <span class="sort">{{ `0${index + 1}` }}</span>
                <span>{{ item.title }}</span>
              </div>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    // 公益性质列表
    natureData: Array
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scope>
.nature-container {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  .grid-list {
    height:250px;
    overflow: hidden;
    overflow-y: auto;
    padding-right:40px;
        &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(245,247,249, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(245,247,249, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    ul {
      li {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        .sort {
          font-size: 18px;
          font-weight: bold;
          color: #222222;
          line-height: 30px;
          vertical-align: middle;
          margin-right: 15px;
        }
        .slthover{
          padding: 10px 0;
          margin-left: 40px;
          padding-left: 10px;
          display: inline-block;        
        }
        .slthoverImprot {
          font-size: 18px;
            background: #FFFFFF;
            box-shadow: 0px 6px 20px 0px rgba(204, 204, 204, 0.4);
            transform: scale(1.1);
            transform: translateX(10px);
          }
      }
      .sort-icon {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 15px;
          top: 50%;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          background: rgba(202, 34, 34, 0.4);
          border-radius: 50%;
        }
      }
      .sort-iconcolor {
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 15px;
          top: 50%;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          background: #CA2222;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>