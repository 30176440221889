<template>
  <div class="journalism-details">
    <div class="journalism-details-con ">
      <div class="journalism-details-con-title">
        {{ title }}
      </div>
      <div class="con"
           v-if="noticeObj">
        <div class="valContent"
             v-html="noticeObj"></div>
      </div>
      <div class="line"></div>
      <div class="butt">没有更多了</div>
    </div>
  </div>
</template>

<script>
import { extensionVolunteer, bannerDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  name: 'journalismDetails',
  props: {
    title: String,
    type: Number
  },
  data () {
    return {
      imageUrl: '',
      noticeObj: {},
      journaDetailsBanner: []
    }
  },
  created () {
    this.imageUrl = config.imageUrl
    // 详情轮播
    bannerDetail({
      type: '5'
    }).then(res_ => {
      this.journaDetailsBanner = res_.data
    })
    // 详情
    // 导师
    extensionVolunteer().then(res_ => {
      if (this.$route.query.type == 0) {
        this.noticeObj = res_.data.scope
      } else if (this.$route.query.type == 1) {
        this.noticeObj = res_.data.treatment
      } else {
        this.noticeObj = res_.data.conditions
      }
    })
  },
  filters: {
    contentVal (val) {
      if (val == null) {
        return false
      } else {
        let regx = /<[^>]*>|<\/[^>]*>/gm
        let valRegx1 = val.replace(regx, '')
        let valRegx2 = valRegx1.replace(/&rdquo/g, '')
        let valRegx3 = valRegx2.replace(/&ldquo/g, '')
        let valRegx4 = valRegx3.replace(/&mdash/g, '')
        return valRegx4
      }
    }
  },
  watch: {
    type () {
      extensionVolunteer().then(res_ => {
        if (this.type == 0) {
          this.noticeObj = res_.data.scope
        } else if (this.type == 1) {
          this.noticeObj = res_.data.treatment
        } else {
          this.noticeObj = res_.data.conditions
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumb {
  padding: 20px 0;
  font-size: $mo-font-size-base;
  ::v-deep.el-breadcrumb__inner {
    color: $mo-main-color;
    font-weight: 600;
  }
  ::v-deep.is-link {
    color: $mo-text-color-grey;
    font-weight: 500;
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #bfc6cc;
}
.journalism-details-con {
  .con {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 37px;
    .valContent {
      text-indent: 1cm;
    }
  }
  .journalism-details-con-title {
    text-align: center;
    font-size: $mo-font-size-xl;
    font-weight: 600;
    line-height: 60px;
  }
}
.butt {
  color: $mo-text-color-placeholder;
  text-align: center;
  line-height: 70px;
}
</style>
