<template>
  <div class="volunter">
    <div class="volunter-bg">
      <el-image
        :src="require('../../../assets/images/volunteer/icon_74.png')"
        style="height:100%;width:100%"
      ></el-image>
    </div>
    <div class="main">
      <div class="title">
        <el-row :gutter="200">
          <el-col :span="12">
            <div class="tutor">
              <span :class="tab ? 'active' : ''" @click="tutor()"
                >阅读导师志愿者</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="extension">
              <span :class="!tab ? 'active' : ''" @click="extension()"
                >家长阅读志愿者</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <Tutor v-show="tab" :tutorContent="tutorData"></Tutor>
      <Extension v-show="!tab" :extensionContent="extensionData"></Extension>
    </div>
  </div>
</template>

<script>
import Tutor from './Tutor'
import Extension from './Extension'

export default {
  props: {
    extensionData:Object,
    tutorData: Object
  },
  components: {
    Tutor,
    Extension
  },
  data() {
    return {
      tab: true,
    }
  },
  methods: {
    tutor() {
      this.tab = true
    },
    extension() {
      this.tab = false
    }
  },
}
</script>

<style lang="scss" scoped>
.volunter {
  margin-bottom: 40px;
  position: relative;
  .volunter-bg {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
  .main {
    width: 1200px;
    padding: 30px 0;
    margin: 0 auto;
    .title {
      .tutor,
      .extension {
        text-align: right;
        span {
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          display: inline-block;
          line-height: 42px;
        }
        .active {
          font-size: 22px;
          font-weight: 600;
          color: #ffffff;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: #ffffff;
            position: absolute;
            right: 0;
            top: 50px;
          }
        }
      }
      .extension {
        text-align: left;
      }
    }
  }
}
</style>