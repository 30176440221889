// 懒加载路由
const bookStoreDetails = () => import('./bookStoreDetails.vue')

export default {
  path: '/bookStoreDetails',
  name: 'bookStoreDetails',
  component: bookStoreDetails,
  meta: {
    title: '书籍详情',
    authLogin: false //是否需要登录
  },
  children: []
}
