<template>
  <div class="member-unit">
    <div class="image">
      <el-image
        :src="require('../../../assets/images/home/icon_6.png')"
        style="height: 100%"
        alt=""
      ></el-image>
    </div>
    <div class="member-unit-inner container">
      <div class="member-unit-inner-title">会员单位</div>
      <div class="member-unit-list" ref="scroll">
        <div :class="['member-unit-content',schoolFirm.length <= 4 ? 'flex' : '' ]">
          <div class="item" v-for="(item, index) in schoolFirm" :key="index" @click.stop="clickHandler(item.url)">
            <div class="title">
              {{ item.way ? item.way : '合作学校' }}
            </div>
            <div class="con">
              <div class="con-inner" v-if="!item.way">
                {{ item.name }}
              </div>
              <div class="img" v-if="item.way">
                <img :src="`${imageUrl + item.image}`" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core'

export default {
  props: {
    schoolFirm: Array,
    imageUrl: String,
  },
  name: 'MemberUnit',
  data() {
    return {
      scroll: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs.scroll, {
          scrollX: true,
        })
      })
    },
    clickHandler(link) {
      window.open(`http://${link}`)
    }
  },
}
</script>

<style scoped lang="scss">
.member-unit {
  min-width: $mo-main-width-base;
  position: relative;
  .image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 100%;
  }
  .member-unit-inner {
    width: $mo-main-width-base;
    padding-bottom: 30px;
  }
  .member-unit-inner-title {
    text-align: center;
    width: 100%;
    font-size: $mo-font-size-xl;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #222222;
    line-height: 65px;
  }
  .member-unit-list {
    white-space: nowrap;
    overflow: hidden;
    width: 1200px;
    .member-unit-content {
      display: inline-block;
    }
    .flex {
      display: flex;
      justify-content: space-evenly;
    }
    .item {
      cursor: pointer;
      margin-right: 20px;
      display: inline-block;
      width: 224px;
      height: 130px;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 20px;
      &:hover {
        .con {
          transform: scale(1.1);
        }
      }
      .title {
        line-height: 40px;
        border-bottom: 1px solid #e1e1e1;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
      .con {
        line-height: 60px;
        text-align: center;
        flex-grow: 1;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        .con-inner {
          line-height: 60px;
        }
      }
      .img {
          margin: 0 auto;
        width: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
