const Cooperation = () => import('./Cooperation.vue')
export default {
  path: '/Cooperation',
  name: 'Cooperation',
  component: Cooperation,
  meta: {
    title: '会员单位',
    authLogin: false //是否需要登录
  },
  children: []
}

