<template>
  <div class="journalism">
    <keep-alive><AppHeader :default-index="2"></AppHeader></keep-alive>
    <Banner :list="journalistBanner"></Banner>
    <div class="list">
      <JournalismItem :detailList="journaDetails"></JournalismItem>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :total="PageNumber.count"
          :page-size="5"
        >
        </el-pagination>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import JournalismItem from '@/components/content/journalism/JournalismItem'
import { bannerDetail, notice } from '@/services/api.js'

export default {
  name: 'journalism',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    JournalismItem,
  },
  mixins: [],
  data: function () {
    return {
      journalistBanner: [],
      journaDetails: [], // 公告列表
      PageNumber: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    // 轮播图
    bannerDetail({
      type: '2',
    }).then((res_) => {
      this.journalistBanner = res_.data
    })
    this.getNotice('1')
  },
  mounted() {},
  methods: {
    handleCurrentChange(val) {
      this.getNotice(val)
    },
    getNotice(val) {
      // 新闻公告
      notice({
        noticeAtt: '1',
        pageNo: val,
        pageSize: '5',
      }).then( res_ => {
        this.journaDetails = res_.data.list
        this.PageNumber = res_.data
      })
    }
    
  },
}
</script>

<style scoped lang="scss">
.journalism {
  .list {
    margin-top: 90px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px;
    ::v-deep.active {
      background: #f52021 !important;
    }
  }
}
</style>
