<template>
  <div class="home-title">
    <div class="container">
      <div class="home-title-inner">
        <div class="title">
          {{ title }}
        </div>
        <div class="more" @click="toLink(linkUrl)">
          查看更多
          <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="con">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeTitle',
  props: {
    title: {
      type: String,
      default: '最优秀书评'
    },
    linkUrl: String,
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    toLink(url) {
      this.$router.push({
        path: url,
        query: { index: this.index}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.home-title {
  margin-top: 40px;
  .con {
    margin-top: $mo-spacing-col-lg;
  }
  .home-title-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $mo-main-color 1px solid;
    color: $mo-main-color;
    .title {
      width: 150px;
      height: 42px;
      background: $mo-main-color;
      color: #fff;
      line-height: 42px;
      text-align: center;
    }
    .more {
      cursor: pointer;
      height: 42px;
      line-height: 42px;
      padding: 0 20px;
      &:hover {
        i {
          animation: SlipRight 800ms infinite linear;
        }
      }
    }
  }
}
@keyframes SlipRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(14px);
  }
}
</style>
