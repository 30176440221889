const Volunteer = () => import('./Volunteer.vue')
export default {
  path: '/Volunteer',
  name: 'Volunteer',
  component: Volunteer,
  meta: {
    title: '志愿者',
    authLogin: false //是否需要登录
  },
  children: []
}

