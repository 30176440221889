// 懒加载路由
const CourseDetails = () => import('./CourseDetails.vue')

export default {
  path: '/CourseDetails',
  name: 'CourseDetails',
  component: CourseDetails,
  meta: {
    title: '课程详情',
    authLogin: false //是否需要登录
  },
  children: []
}
