<template>
  <div>
    <keep-alive><AppHeader :default-index="5"></AppHeader></keep-alive>
    <Banner :list="bannerList"></Banner>
    <Block title="志愿者招募"></Block>
    <VolunteerItem :extensionData="extensionObject" :tutorData="tutorObject"></VolunteerItem>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import Block from '@/components/content/Block'
import VolunteerItem from '@/components/content/volunteer/VolunteerItem'
import { bannerDetail,tutorVolunteer,extensionVolunteer } from '@/services/api.js'

export default {
  components: {
    AppHeader,
    Banner,
    Block,
    VolunteerItem,
    AppFooter
  },
  data() {
    return {
      bannerList: [],
      extensionObject:{} , // 阅读推广
      tutorObject: {} // 阅读导师
    }
  },
  created() {
    // 轮播图
    bannerDetail({
      type: '5'
    }).then(res_ => {
      this.bannerList = res_.data
    })
    // 导师
    tutorVolunteer().then(res_ => {
      this.tutorObject = res_.data
    })
    // 推广
    extensionVolunteer().then(res_ => {
      this.extensionObject = res_.data
    })
  }
}
</script>

<style lang="scss" scoped>
</style>