// 懒加载路由
const Home = () => import('./Home.vue')
export default {
  path: '*',
  name: 'home',
  component: Home,
  meta: {
    title: '首页',
    authLogin: false //是否需要登录
  },
  children: []
}
