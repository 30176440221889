<template>
  <div class="school-container">
    <AppHeader @index="tabIndex" :default-index="3" ></AppHeader>
    <Banner :list="bannerList"></Banner>
    <SchoolItem v-if="index == 0" :propData="schoolList" :imageUrl="imageUrl"></SchoolItem>
    <EnterpriseItem v-else :propsData="firmList" :imageUrl="imageUrl"></EnterpriseItem>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import SchoolItem from '@/components/content/cooperation/SchoolItem'
import EnterpriseItem from '@/components/content/cooperation/EnterpriseItem'
import { bannerDetail, school, Firm } from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    Banner,
    SchoolItem,
    EnterpriseItem,
    AppFooter
  },
  data() {
    return {
      index: 0,
      imageUrl:'',
      schoolList: [],
      firmList: [],
      bannerList: []
    }
  },
  created() {
    this.imageUrl = config.imageUrl
    this.index = this.$route.query.index
    this.getBanner()
    this.getSchoolList()
    this.getFirmList()
  },
  methods: {
    tabIndex(i){
      this.index = i
      this.getBanner()
    },
    getBanner() {
      if(this.index == 0){
        bannerDetail({
          type: '4'
        }).then( res_ => {
          this.bannerList = res_.data 
        })
      }else{
        bannerDetail({
          type: '3'
        }).then( res_ => {
          this.bannerList = res_.data 
        })
      }
    },
    getSchoolList() {
      school().then(res_ => {
        this.schoolList = res_.data
      })
    },
    getFirmList() {
      Firm().then(res_ => {
        this.firmList = res_.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.school-container {
  
}
</style>