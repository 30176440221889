<template>
  <div>
    <keep-alive>
      <AppHeader :default-index="1"></AppHeader>
    </keep-alive>
    <Banner :list="bannerList"></Banner>
    <Block title="1+X项目规划">
      <OneX :ptdOneXData="ptdOneXData"
            :imageUrl="imageUrl"></OneX>
    </Block>
    <div class="nature">
      <div class="image">
        <el-image style="height: 100%"
                  :src="require('../../assets/images/projectIntroduction/icon_29.png')"></el-image>
      </div>
      <div class="nature-main">
        <Block title="项目的公益性质"
               background="#F5F7F9">
          <Nature :natureData="ptdNatureList"></Nature>
        </Block>
      </div>
    </div>
    <Block title="项目的资源优势">
      <Advantage :advantage="ptdSuperiority"
                 :imageUrl="imageUrl"></Advantage>
    </Block>
    <Block title="项目的服务介绍">
      <div class="servicebg">
        <el-row>
          <el-col :span="12"
                  class="leftbg"> </el-col>
          <el-col :span="12"
                  class="rightbg"> </el-col>
        </el-row>
        <div class="solute">
          <el-row>
            <el-col :span="12">
              <div class="left-image">
                <el-image :src="
                    require('../../assets/images/projectIntroduction/icon_42.png')
                  "
                          fit="fill"></el-image>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="right-image">
                <el-image :src="
                    require('../../assets/images/projectIntroduction/icon_43.png')
                  "
                          fit="fill"></el-image>
<!--                <span class="foundation">基金会</span>-->
<!--                <span class="school">学校</span>-->
<!--                <span class="sources">学校来源</span>-->
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </Block>
    <Block title="阅读基地成果展示">
      <Achievements :achList="ptdResultList"></Achievements>
    </Block>
    <div class="characteristic">
      <Block title="特色服务内容"
             background="#F5F7F9">
        <Characteristic></Characteristic>
      </Block>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import Block from '@/components/content/Block'
import OneX from '@/components/content/projectIntroduction/OneX'
import Nature from '@/components/content/projectIntroduction/Nature'
import Advantage from '@/components/content/projectIntroduction/Advantage'
import Achievements from '@/components/content/projectIntroduction/Achievements'
import Characteristic from '@/components/content/projectIntroduction/Characteristic'
import {
  bannerDetail,
  natureList,
  resultShow,
  superiority,
  projectPlan
} from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    Banner,
    Block,
    OneX,
    Nature,
    Advantage,
    Achievements,
    Characteristic,
    AppFooter
  },
  data () {
    return {
      bannerList: [],
      ptdOneXData: {}, // 项目规划
      ptdNatureList: [], // 项目公益性质
      ptdResultList: {}, // 阅读基地展示成果
      ptdSuperiority: [], // 项目优势
      imageUrl: ''
    }
  },
  created () {
    this.imageUrl = config.imageUrl
    bannerDetail({
      type: '1'
    }).then(res_ => {
      this.bannerList = res_.data
    })
    // 项目规划
    projectPlan().then(res_ => {
      this.ptdOneXData = res_.data
    })
    // 项目公益性质
    natureList().then(res_ => {
      this.ptdNatureList = res_.data.list
    })
    // 阅读基地展示成果
    resultShow().then(res_ => {
      this.ptdResultList = res_.data
    })
    // 资源优势
    superiority().then(res_ => {
      console.log(res_)
      this.ptdSuperiority = res_.data
    })
  }
}
</script>

<style scoped lang="scss">
.nature {
  position: relative;
  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.servicebg {
  position: relative;
  .solute {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -600px;
    width: 1200px;
  }
  .leftbg {
    background: #f5f7f9;
    height: 420px;
  }
  .rightbg {
    background: #ffffff;
    height: 420px;
    border-right: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }
  .left-image {
    padding: 60px 50px;
  }
  .right-image {
    //padding-top: 30px;
    //margin: 50px 120px;
    position: relative;
    span {
      font-size: 18px;
      font-weight: 600;
      color: #222222;
    }
    .foundation {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -70px;
    }
    .school {
      position: absolute;
      left: -12%;
      bottom: 23%;
    }
    .sources {
      position: absolute;
      bottom: 23%;
      right: -5%;
    }
  }
}
.characteristic {
  background: #f5f7f9;
}
</style>
