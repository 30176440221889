import http from '@/utils/http'

// pc端轮播图
export function bannerDetail(data) {
  return http.get('app/commonwealPcBanner/list', data)
}

// 新闻公告
// 公告 noticeAtt=1
// 资讯 noticeAtt=2
export function notice(data) {
  return http.get('app/commonwealNotice/list', data, {showLoading:false})
}   

// 新闻详情
// 参数id
export function noticeDetail(data) {
  return http.get('app/commonwealNotice/detail', data)
}

// 合作学校
export function school(data) {
  return http.get('app/cooperationSchool/list', data)
} 

// 合作企业
export function Firm(data) {
  return http.get('app/cooperationFirm/list', data)
}

// 公益课程property=2
export function videoList(data) {
  return http.get('goods/list', data)
}

// 读书榜单property=1
export function hotList(data) {
  return http.get('goods/hotList', data)
}

// 家长阅读志愿者申请
export function volunteerApply(data) {
  return http.post('app/volunteer/apply', data)
}

// 阅读导师志愿者申请
export function tourVolunteer(data) {
  return http.post('app/tourVolunteer/apply', data)
}

// 年纪字典
export function dictGrade(data) {
  return http.get('app/dict/list', data)
}

// 项目公益性质
export function natureList(data) {
  return http.get('app/commonwealNature/list', data)
}

// 项目规划
export function projectPlan(data) {
  return http.get('app/projectPlan/list', data)
}

// 阅读基地展示成果
export function resultShow(data) {
  return http.get('app/resultShow/list', data)
}

// 资源优势
export function superiority(data) {
  return http.get('app/resourceSuperiority/list', data)
}

// 优秀书评
export function excellentList(data) {
  return http.get('order/appraise/excellentList', data, {showLoading:false})
}

// 课程详情图书详情
export function goodsDetail(data) {
  return http.get('goods/detail', data)
}

// 读书榜单排行榜
export function goodsBookTop(data) {
  return http.get('goods/bookTop', data, {showLoading:false})
}

// 导师
export function tutorVolunteer(data) {
  return http.get('app/volunteerRecruit/tutorVolunteer', data, {showLoading:false})
}

// 推广
export function extensionVolunteer(data) {
  return http.get('app/volunteerRecruit/extensionVolunteer', data, {showLoading:false})
}

// 公益网站_校长读书会
export function principalReadingInfo(data) {
  return http.get('app/principalReading/info', data, {showLoading:false})
}

// 公益网站_阅读培训
export function readTrainingInfo(data) {
  return http.get('app/readTraining/info', data, {showLoading:false})
}