<template>
  <div class="book" v-loading="BookReviewLoading">
    <div class="list" v-for="(item, index) in bookReviewList" :key="index">
      <el-row>
        <el-col :span="24" class="bg">
          <div class="list-left">
            <el-image :src="`${imageUrl + item.serve.mainImage}`" fit="fill"></el-image>
            <span>{{ item.serve.name }}</span>
          </div>
          <div class="list-right">
            <div class="information">
              <el-avatar :size="52" :src="`${item.member.photo}`"></el-avatar>
              <span class="name">{{ item.member.name }}</span>
              <el-rate
                :value="parseInt(item.star)"
                disabled
                text-color="#ff9900"
              ></el-rate>
              <span class="date">{{ item.createDate }}</span>
            </div>
            <div :class="['desc', isWhole == index ? '' : 'e-4']">
              <p v-html="item.content">

              </p>
            </div>
            <div
              v-if="item.content.length > 285"
              class="whole"
              @click="isWhole == index ? closeAll() : openAll(index)"
            >
              <span v-show="isWhole != index">【展开全部】</span>
              <span v-show="isWhole == index">【收起全部】</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bookReviewList: Array,
    imageUrl: String,
  },
  data() {
    return {
      index: 0,
      value: 3.7,
      isWhole: -1,
      BookReviewLoading: true,
    }
  },
  created() {
    if(this.bookReviewList){
      this.BookReviewLoading = false
    }
  },
  methods: {
    openAll(index) {
      this.isWhole = index
    },
    closeAll() {
      this.isWhole = -1
    },
  },
}
</script>

<style scoped lang="scss">
.book {
  width: 1200px;
  margin: 0 auto;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    span {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      vertical-align: middle;
    }
    .img {
      width: 24px;
      height: 25px;
      display: inline-block;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .list {
    margin-bottom: 20px;
    &:hover {
      transform: scale(1.05);
    }
    .bg {
      background: #ffffff;
      box-shadow: 0px 4px 20px 0px rgba(204, 204, 204, 0.4);
      border-radius: 8px;
      padding: 20px 20px 30px 20px;
      display: flex;
      align-items: flex-start;
    }
    &-left {
      flex-shrink: 0;
      width: 128px;
      margin-right: 40px;
      text-align: center;
      el-image {
        width: 128px;
        height: 145px;
      }
      span {
        display: inline-block;
        margin-top: 10px;
      }
    }
    &-right {
      .information {
        display: flex;
        align-items: center;
        .name {
          font-size: 20px;
          font-weight: 600;
          color: #222222;
          margin-left: 10px;
          margin-right: 20px;
        }
        .date {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          margin-left: 20px;
        }
      }
      .desc {
        margin-top: 20px;
        // max-height: 120px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #222222;
          line-height: 30px;
        }
      }
      .e-4 {
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .whole {
        cursor: pointer;
        color: #ca2222;
        font-size: 14px;
        position: relative;
        span {
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }
  }
}
</style>