<template>
  <div class="custom-header">
    <el-footer height="100%">
      <div class="app-header container">
        <div class="app-header-inner">
          <div class="logo">
            <div class="icon">
              <a href="/"></a>
              <img src="~images/home/icon_10.png" alt="" />
            </div>
            <div class="logo-left">
              <div class="logo-left-t">山西省少年儿童1+X公益阅读网</div>
              <a
                class="logo-left-b"
                href="//www.rtprpc.com"
                target="_blank"
                @click.stop
                >www.rtprpc.com</a
              >
            </div>
          </div>
          <div class="middle">
            <ul class="middle-ul">
              <li @click="toLink('/ProjectIntroduction')"><a  class="pointer">关于我们</a></li>
              <li><a>|</a></li>
              <li><a @click="toLink('/Cooperation',0)" class="pointer">公益联盟</a></li>
              <li><a>|</a></li>
              <li><a @click="toLink('/Volunteer')" class="pointer">加入我们</a></li>
            </ul>
            <div class="middle-main">
              <p>主办单位：<a href="https://www.tyruitu.com" target="_blank" class="pointer"> 太原睿图文化传播有限公司 </a></p>
              <p>技术支持：山西雅裕网络科技有限公司 </p>
            </div>
            <div class="middle-main">
              <p>联系电话：19103424220 、19935160953</p>
              <p>电子邮箱：1463158766@qq.com</p>
              <!-- <p><a></a></p>
              <p><a></a></p>
              <p><a></a></p> -->
            </div>
            <div class="middle-main">
              <p>Copyright &copy; 太原睿图文化传播有限公司</p>
            </div>
            <div class="middle-main">
              <p class="active pointer" @click="toOuter('https://beian.miit.gov.cn')">
                晋ICP备2021004382号-1
              </p>
              <p class="jingan pointer">
                <a
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502050949"
                  ><img src="../../../assets/images/jingan.png" />
                  <p style="padding: 0 10px 0 10px">
                    晋公网安备 14010502050949号
                  </p></a
                >
              </p>
            </div>
          </div>

          <!-- <div class="left">
            <div class="left-title">联系我们</div>
            <div class="item">
              <i class="el-icon-phone-outline"></i> 19103424220<br />
              <i class="el-icon-phone-outline"></i> 19935160953
            </div>
            <div class="item">
              <i class="el-icon-message"></i> 1463158766@qq.com
            </div>
          </div>

          <ul v-for="(v, i) in list" :key="i" >
            <li class="title">{{ v.title }}</li>
            <li
              v-show="v.list"
              v-for="(item, index) in v.list"
              :key="index"
              :class="item.link || item.url ? 'pointer' : ''"
              @click="item.link ? toLink(item) : toOuter(item.url)"
            >
              {{ item.title }}
            </li> -->
          <!-- <li v-show="!v.list" v-for="(unitItem, unitIndex) in unit" :key="unitIndex" class="pointer" @click="toOuter(unitItem.url)">
              {{ unitItem.name }}
            </li> -->
          <!-- </ul> -->
          <!-- <div class="line"></div>
          <div class="record">
            <p>Copyright &copy; 太原睿图文化传播有限公司</p>
            <p class="active" @click="toOuter('https://beian.miit.gov.cn')">
              晋ICP备2021004382号-1
            </p>
            <p class="jingan">
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502050949"
                ><img src="../../../assets/images/jingan.png" />
                <p style="padding: 0 10px 0 10px">
                  晋公网安备 14010502050949号
                </p></a
              >
            </p>
          </div> -->
          <!-- <div class="line"></div> -->
          <div class="right">
            <img src="~images/home/icon_14.jpg" alt="" />
          </div>
        </div>
        <!-- <div class="record">
          <p>Copyright &copy; 太原睿图文化传播有限公司</p>
          <p class="active" @click="toOuter('https://beian.miit.gov.cn')">
            晋ICP备2021004382号-1
          </p>
          <p class="jingan">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502050949"
              ><img src="../../../assets/images/jingan.png" />
              <p style="padding: 0 10px 0 10px">
                晋公网安备 14010502050949号
              </p></a
            >
          </p>
        </div> -->
      </div>
    </el-footer>
  </div>
</template>


<script>
export default {
  props: {
    // 合作单位
    unit: Array,
  },
  name: 'AppFooter',
  data() {
    return {
      list: [
        {
          title: '关于我们',
          list: [
            {
              title: '了解1+x公益阅读',
              link: '/ProjectIntroduction',
            },
            {
              title: '志愿者招募',
              link: '/Volunteer',
            },
          ],
        },
        {
          title: '主办单位',
          list: [
            {
              title: '太原睿图文化传播有限公司',
              link: '',
              url: 'https://www.tyruitu.com',
            },
          ],
        },
        // {
        //   title: '合作单位',
        //   list: [
        //     {
        //       title: '山西xx小学',
        //       link: ''
        //     },
        //     {
        //       title: '山西xx小学',
        //       link: ''
        //     }
        //   ]
        // },
        {
          title: '技术支持',
          list: [
            {
              title: '山西雅裕网络科技有限公司',
              link: '',
            },
          ],
        },
      ],
    }
  },
  methods: {
    toLink(link,index) {
      this.$router.push({
        path: link,
        query:{index}
      })
    },
    // 合作单位跳转
    toOuter(link) {
      window.open(`${link}`)
    },
  },
}
</script>

<style scoped lang="scss">
.el-footer {
  background: #f5f5f5;
}
.custom-header {
  width: 100%;
  background: #535254;
  min-width: $mo-main-width-base;
  .app-header-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    color: #fff;
    // padding-top: $mo-spacing-col-lg;
    padding: 20px 0 0 0;
    .left {
      text-align: center;
      line-height: 35px;
      .left-title {
        font-size: $mo-font-size-lg;
        font-weight: 600;
      }
      .item {
        font-size: $mo-font-size-sm;
      }
    }
    li {
      text-align: center;
      font-size: $mo-font-size-sm;
      line-height: 35px;
    }
    .line-after {
      position: relative;
      padding-left: 20px;
      &::after {
        content: '';
        // display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 160px;
        background: #fff;
        opacity: 0.18;
      }
    }
    .title {
      font-size: $mo-font-size-lg;
      font-weight: 600;
    }
    .right {
      width: 120px;
      height: 120px;
      background: #fff;
      border: 5px solid #fff;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .record {
    display: flex;
    justify-content: center;
    p {
      color: #fff;
      font-size: 12px;
      padding: 0 10px;
    }
    .active {
      cursor: pointer;
    }
  }
  .line {
    width: 1px;
    height: 160px;
    background: #fff;
    opacity: 0.18;
  }
  .jingan a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px 0;
  }
  .jingan img {
    width: 16px;
    height: 16px;
  }
  .logo {
    display: flex;
    align-items: center;

    .icon {
      width: 60px;
      height: 60px;
      position: relative;
      margin-right: $mo-spacing-row-sm;

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .logo-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ca2222;

      &-t {
        font-size: 25px;
        font-weight: 600;
      }

      &-b {
        font-size: 18px;
        color: #ca2222;
        line-height: 25px;
      }
    }
  }
  .middle {
    padding: 0 40px;
    position: relative;
    &::before {
      content: '';
      position:absolute;
      width: 1px;height: 165px;
      background:#B9B7B6;
      top: 0;
      left: 0;
    }
    &::after{
      content: '';
      position:absolute;
      width: 1px;height: 165px;
      background:#B9B7B6;
      top: 0;
      right: 0;
    }
    background: none;
    .middle-ul {
      justify-content: center;
      display: flex;
      align-items: center;
      li {
        &:nth-child(2n) {
          margin: 0 15px;
        }
        a {
          font-size: 14px;
          font-weight: 600;
          color: #222222;
        }
      }
    }
    &-main {
      margin: 14px 0;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      color: #222222;
      p {
        margin-right: 10px;
      }
    }
  }
}
</style>
