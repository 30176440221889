import { render, staticRenderFns } from "./CourseItem.vue?vue&type=template&id=2b287ed0&scoped=true&"
import script from "./CourseItem.vue?vue&type=script&lang=js&"
export * from "./CourseItem.vue?vue&type=script&lang=js&"
import style0 from "./CourseItem.vue?vue&type=style&index=0&id=2b287ed0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b287ed0",
  null
  
)

export default component.exports