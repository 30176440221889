<template>
  <div class="middle">
<!--    <div class="middle-header" v-if="trainingContent.logo">-->
<!--      <img :src="imageUrl + trainingContent.logo" alt="" />-->
<!--    </div>-->
    <div class="middle-main">
      <h3 class="title">{{ trainingContent.title }}</h3>
      <p class="desc" v-html="trainingContent.content">
      </p>
    </div>
    <div class="middle-footer" v-if="!trainingContent.url">
      <div class="middle-footer-item pointer" @click="download">
        <img src="../../../assets/images/teaching/icon_83.png" alt="" />
        <span>表格下载</span>
      </div>

      <div class="middle-footer-item">
        <img src="../../../assets/images/teaching/icon_81.png" alt="" />
        <span>联系邮箱：</span>
        <span>{{trainingContent.email}}</span>
      </div>
      <div class="middle-footer-item">
        <img src="../../../assets/images/teaching/icon_82.png" alt="" />
        <span>联系地址：</span>
        <span>{{trainingContent.address}}</span>
      </div>
    </div>
    <div class="middle-footer" v-else>
      <div class="middle-footer-item">
        <!-- <i class=""></i> -->
        <img src="../../../assets/images/teaching/icon_82.png" alt="" />
        <span>链接：</span>
        <a :href="trainingContent.url" target="_blank">{{trainingContent.url}}</a>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {
    trainingContent: Object,
  },
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      imageUrl:this.$http.imageUrl
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    download(){
      window.open(this.imageUrl + this.trainingContent.enclosure)
    }

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.middle {
  width: 1200px;
  margin: 30px auto;
  &-header {
    width: 100%;
    //height: 200px;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-main {
    .title {
      text-align: center;
      color: #222222;
      font-size: 24px;
      font-weight: 600;
    }
    .desc {
      color: #222;
      font-weight: 400;
      font-size: 16px;
      margin: 30px 0;
      line-height: 40px;
    }
  }
  &-footer {
    padding: 33px 50px;
    width: 100%;
    background: #f5f7f9;
    display: flex;
    align-items: center;
    text-align: center;
    &-item {
      flex: 1;
      position: relative;
      font-size: 16px;
      color: #222222;
      line-height: 47px;
      img {
        width: 26px;
        height: 20px;
        margin-right: 20px;
      }
      &:last-child {
        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 47px;
          background: #f5f7f9;
        }
      }
      &::after {
        content: '';
        vertical-align: middle;
        position: absolute;
        width: 1px;
        height: 47px;
        right: 0;
        background: #b8bcbf;
      }
    }
  }
}
</style>
